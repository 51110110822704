
import React, { useState,useEffect,useCallback, useRef } from "react"
import {
Container,
Button,
Modal,
ModalBody,
ModalHeader,
ModalFooter,
Nav,
NavItem,
NavLink,
TabContent,
TabPane,
Label,
Input,
} from "reactstrap"

import "react-multi-carousel/lib/styles.css"

import { Link,useParams, useLocation, useNavigate  } from "react-router-dom"
import classnames from "classnames"

import awicon from "../../../assets/images/services-icon/coin.png"
import Select from "react-select"


import dsmile from "../../../assets/images/services-icon/dog_smile.png"

//i18n
import axios from "axios";
// import { BASE_URL } from "../../global";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";

import Loader from "react-spinner-loader";




const Pagination = React.memo(({ActivityData1, keyData, triggerSetActiveData}) =>{



  const [ActivityData, setActivityData] = useState([]);
    //categoty 1 ,8
const [ActiveData, setActiveData] = useState(null);
const [ActivityPart, setActivityPart] = useState([]);
//const [IsFirstQuestion, setIsFirstQuestion] = useState(0);
 // const [IsLastQuestion, setIsLastQuestiont] = useState(0);

  const [customActiveTab, setcustomActiveTab] = useState("1")

 let active_state = 1;
  let i = 0;
   // return
   let IsFirstQuestion = useRef(0);
   let IsLastQuestion = useRef(0);

   let IsTotalTab = useRef(0);
   let IsCurrentTab = useRef(0);
   let IsCurrentTabTotalPart = useRef(0);
   let IsCurrentTabCurrentPart = useRef(0);

   useEffect(() => {
      
    if(ActivityData1 && ActivityData1 != '' && ActivityData1!=undefined){
      //reset data
      setActivityData(ActivityData1);
      setActiveData(null);
      setActivityPart([])
      IsFirstQuestion.current=0;
      IsLastQuestion.current=0;

      IsTotalTab.current=0;
      IsCurrentTab.current=0;
      IsCurrentTabTotalPart.current=0;
      IsCurrentTabCurrentPart.current=0;
      active_state = 1;
      i = 0;
    }
  }, [ActivityData1])
  //console.log(ActivityData);

   useEffect(() => {
    if(ActiveData!='' && IsLastQuestion.current !== 0){
      //console.log(IsTotalTab.current);
      triggerSetActiveData({activeData : ActiveData, 
        IsLastQuestion :IsLastQuestion.current, 
        IsFirstQuestion : IsFirstQuestion.current, 
        ActivityPartData : ActivityPart,
        IsTotalTab : IsTotalTab.current,
        IsCurrentTab : IsCurrentTab.current,
        IsCurrentTabTotalPart : IsCurrentTabTotalPart.current,
        IsCurrentTabCurrentPart : IsCurrentTabCurrentPart.current});
    }
  }, [IsLastQuestion, IsFirstQuestion, ActiveData]);
  //console.log(ActivityData);

  return (
    ActivityData && ActivityData.length > 0 ?
            
       ActivityData.map(
        (activity, index) => {
          //console.log(enrolment)
          IsTotalTab.current = ActivityData.length;
          if (
            activity.english_question &&
            activity.english_question.length > 0 &&
            parseInt(activity.fk_sub_id) === 1
          ) {
              
                return activity.english_question.map(
                  (question, index1) => {
                   
                      if (
                        parseInt(question.english_question_category_id) === 1 ||
                        parseInt(question.english_question_category_id) === 8 ||
                        parseInt(question.english_question_category_id) === 2 ||
                        parseInt(question.english_question_category_id) === 12 
                      ) {


                        let filteredManualMark = question.english_question_parts.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0))) 
                        let question_status = 1;
                        
                       // console.log(active_state)
                            if (
                              question.english_question_parts &&
                              question.english_question_parts.length > 0
                            ) {
  
  
                             //let total_coins = question.english_question_parts.length * 2;
                             //setTotalCoins(prevTotalCoins => prevTotalCoins + total_coins);
                             question.english_question_parts.forEach((activityQuestionPart, index2) => {
                              let partManualMark = parseInt(activityQuestionPart.english_question_part_automark);
                              i++;
                              let total_mark = 0;
                                if (activityQuestionPart.StudentAnsAttempts && activityQuestionPart.StudentAnsAttempts.length > 0) {
                                  
                                  activityQuestionPart.StudentAnsAttempts.forEach((stuAnswer, index3) => {
                                    
                                    if (stuAnswer.points_obtained!=null) {
                                      total_mark += parseInt(stuAnswer.points_obtained); // Increment count_answer by 1 for each occurrence
                                    }
                                  });
  
                                if(activityQuestionPart.StudentAnsAttempts.length >=2 && total_mark === 0){
                                  question_status = 3;
                                }else if(activityQuestionPart.StudentAnsAttempts.length >=1 && total_mark >= 2){
                                  question_status = 4;
                                  
                                }else if(activityQuestionPart.StudentAnsAttempts.length >=2 && total_mark > 0){
                                  question_status = 5;
                                 
                                }else if(activityQuestionPart.StudentAnsAttempts.length ===1 && total_mark >= 0 && partManualMark <= 0){
                                  question_status = 2;
                                 
                                }else{
                                 if(parseInt(active_state) === 1){
                                 // console.log('active')
                                    question_status = 1;
                                    active_state = 0;
                                    IsFirstQuestion.current = i;
                                    IsCurrentTab.current = index + 1;
                                    IsCurrentTabTotalPart.current= question.english_question_parts.length;
                                    IsCurrentTabCurrentPart.current= index2 +1;
                                       //setIsFirstQuestion(i);
                                        if(ActiveData == null){
                                          // setActiveData(activityQuestionPart);
                                          setActiveData(activity);
                                          setActivityPart([activityQuestionPart]);

                                        }
                                  
                                  }else{
                                    question_status = 2;
                                  }
                                }
  
                                }else{
                                  if(parseInt(active_state) === 1){
                                    question_status = 1;
                                    active_state = 0;
                                   
                                      // setIsFirstQuestion(i);
                                   IsFirstQuestion.current = i;
                                   IsCurrentTab.current = index + 1;
                                   IsCurrentTabTotalPart.current= question.english_question_parts.length;
                                   IsCurrentTabCurrentPart.current= index2 +1;
                                      if(ActiveData == null){
                                        // setActiveData(activityQuestionPart);
                                        setActiveData(activity);
                                        setActivityPart([activityQuestionPart]);
                                      }
                                  }else{
                                    question_status = 2;
                                  }
                                 
                                }
                               // console.log('status -'+i+' - '+question_status);
                               
                              });
                             
                            }
                            IsLastQuestion.current = i;
                            //setIsLastQuestiont(i);
                           
                            return (
                              <NavItem key={index}>
                                {/* { parseInt(question.english_question_category_id) === 8 ?  




                                  <NavLink style={{ cursor: "pointer" }} className={ classnames({ active: customActiveTab=== index+1 })+ " display-hold_active"}> 
                                    <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span>
                                  </NavLink> 
                                  :  */}
                                <NavLink style={{ cursor: "pointer" }} className={ classnames({ active: customActiveTab=== index+1 })
                                  + IsCurrentTab.current == index+1 ? " display-hold_active" :
                                    question_status && question_status === 1 ? " display-hold_active" : 
                                    question_status &&  question_status === 2 ? " display-number" : 
                                    question_status && question_status === 3 ? " display-attempt_fail" :
                                    question_status && question_status === 4 ? " display-attempt1" :
                                    question_status && question_status === 5 ? " display-attempt2" :
                                     " display-number"} 
                                  >
                                    {IsCurrentTab.current === index+1 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                    question_status && question_status === 1 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                   question_status && question_status === 2 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                   question_status && question_status === 3 ? filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="mdi mdi-close-thick text-danger font-size-20"></i> :
                                   question_status && question_status === 4 ?  filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="fas fa-check text-success font-size-20"></i> :
                                   question_status && question_status === 5 ? filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="fas fa-check text-warning font-size-20"></i> :
                                   <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span>}
                                  
                                </NavLink>

                              {/* } */}



                              </NavItem>
                          )
  
                      }else if(
                        parseInt(question.english_question_category_id) === 13 ||
                        parseInt(question.english_question_category_id) === 10
                      ) {


                        let filteredManualMark = question.english_question_parts.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0))) 
                        let question_status = 1;
                        
                       // console.log(active_state)
                            if (
                              question.english_question_parts &&
                              question.english_question_parts.length > 0
                            ) {
                              let total_part = question.english_question_parts;
                            let array_part_data = total_part.filter(part =>
                              part.english_question_answers.length > 0 && part.english_question_answers[0].english_answer_text 
                                && part.english_question_answers[0].english_answer_text!=''
                          );
                          console.log(array_part_data);
                             //let total_coins = question.english_question_parts.length * 2;
                             //setTotalCoins(prevTotalCoins => prevTotalCoins + total_coins);
                             question.english_question_parts.forEach((activityQuestionPart, index2) => {
                              let partManualMark = parseInt(activityQuestionPart.english_question_part_automark);

                              

                              if(activityQuestionPart.english_question_answers 
                                && activityQuestionPart.english_question_answers.length > 0 
                                && activityQuestionPart.english_question_answers[0].english_answer_text 
                                && activityQuestionPart.english_question_answers[0].english_answer_text!=''){
                                  i++;
                                  let total_mark = 0;


                                if (activityQuestionPart.StudentAnsAttempts && activityQuestionPart.StudentAnsAttempts.length > 0) {
                                  
                                  activityQuestionPart.StudentAnsAttempts.forEach((stuAnswer, index3) => {
                                    
                                    if (stuAnswer.points_obtained!=null) {
                                      total_mark += parseInt(stuAnswer.points_obtained); // Increment count_answer by 1 for each occurrence
                                    }
                                  });
  
                                if(activityQuestionPart.StudentAnsAttempts.length >=2 && total_mark === 0){
                                  question_status = 3;
                                }else if(activityQuestionPart.StudentAnsAttempts.length >=1 && total_mark >= 2){
                                  question_status = 4;
                                  
                                }else if(activityQuestionPart.StudentAnsAttempts.length >=2 && total_mark > 0){
                                  question_status = 5;
                                 
                                }else if(activityQuestionPart.StudentAnsAttempts.length ===1 && total_mark >= 0 && partManualMark <= 0){
                                  question_status = 2;
                                 
                                }else{
                                 if(parseInt(active_state) === 1){
                                 // console.log('active')
                                    question_status = 1;
                                    active_state = 0;
                                    IsFirstQuestion.current = i;
                                    IsCurrentTab.current = index + 1;
                                    IsCurrentTabTotalPart.current= array_part_data.length;
                                    IsCurrentTabCurrentPart.current= index2 +1;
                                       //setIsFirstQuestion(i);
                                        if(ActiveData == null){
                                          // setActiveData(activityQuestionPart);
                                          setActiveData(activity);
                                          setActivityPart([activityQuestionPart]);

                                        }
                                  
                                  }else{
                                    question_status = 2;
                                  }
                                }
  
                                }else{
                                  if(parseInt(active_state) === 1){
                                    question_status = 1;
                                    active_state = 0;
                                   
                                      // setIsFirstQuestion(i);
                                   IsFirstQuestion.current = i;
                                   IsCurrentTab.current = index + 1;
                                   IsCurrentTabTotalPart.current= array_part_data.length;
                                   IsCurrentTabCurrentPart.current= index2 +1;
                                      if(ActiveData == null){
                                        // setActiveData(activityQuestionPart);
                                        setActiveData(activity);
                                        setActivityPart([activityQuestionPart]);
                                      }
                                  }else{
                                    question_status = 2;
                                  }
                                 
                                }


                              }
                               // console.log('status -'+i+' - '+question_status);
                               
                              });
                             
                            }
                            IsLastQuestion.current = i;
                            //setIsLastQuestiont(i);
                           
                            return (
                              <NavItem key={index}>
                                {/* { parseInt(question.english_question_category_id) === 8 ?  




                                  <NavLink style={{ cursor: "pointer" }} className={ classnames({ active: customActiveTab=== index+1 })+ " display-hold_active"}> 
                                    <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span>
                                  </NavLink> 
                                  :  */}
                                <NavLink style={{ cursor: "pointer" }} className={ classnames({ active: customActiveTab=== index+1 })
                                  + IsCurrentTab.current == index+1 ? " display-hold_active" :
                                    question_status && question_status === 1 ? " display-hold_active" : 
                                    question_status &&  question_status === 2 ? " display-number" : 
                                    question_status && question_status === 3 ? " display-attempt_fail" :
                                    question_status && question_status === 4 ? " display-attempt1" :
                                    question_status && question_status === 5 ? " display-attempt2" :
                                     " display-number"} 
                                  >
                                    {IsCurrentTab.current === index+1 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                    question_status && question_status === 1 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                   question_status && question_status === 2 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                   question_status && question_status === 3 ? filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="mdi mdi-close-thick text-danger font-size-20"></i> :
                                   question_status && question_status === 4 ?  filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="fas fa-check text-success font-size-20"></i> :
                                   question_status && question_status === 5 ? filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="fas fa-check text-warning font-size-20"></i> :
                                   <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span>}
                                  
                                </NavLink>

                              {/* } */}



                              </NavItem>
                          )
  
                      }
                      // else if( parseInt(question.english_question_category_id) === 8 ){
                      //   let filteredManualMark = question.english_question_parts.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0))) 
                      //   let question_status = 1;
                      //   let total_mark = 0;
                      //   let part_array = [];
                      //   let completed_part_array = [];
                      //  // console.log(active_state)
                      //       if (
                      //         question.english_question_parts &&
                      //         question.english_question_parts.length > 0
                      //       ) {
  
  
                      //        //let total_coins = question.english_question_parts.length * 2;
                      //        //setTotalCoins(prevTotalCoins => prevTotalCoins + total_coins);
                      //        question.english_question_parts.forEach((activityQuestionPart, index2) => {
                      //         i++;
                      //           if (activityQuestionPart.StudentAnsAttempts && activityQuestionPart.StudentAnsAttempts.length > 0) {
                                  
                      //             activityQuestionPart.StudentAnsAttempts.forEach((stuAnswer, index3) => {
                                    
                      //               if (stuAnswer.points_obtained) {
                      //                 total_mark += parseInt(stuAnswer.points_obtained); // Increment count_answer by 1 for each occurrence
                      //               }
                      //             });
  
                      //           if(activityQuestionPart.StudentAnsAttempts.length ===2 && total_mark === 0){
                      //             question_status = 3;
                      //             completed_part_array.push(activityQuestionPart);
                      //           }else if(activityQuestionPart.StudentAnsAttempts.length ===1 && total_mark >= 2){
                      //             question_status = 4;
                      //             completed_part_array.push(activityQuestionPart);
                      //           }else if(activityQuestionPart.StudentAnsAttempts.length ===2 && total_mark > 0){
                      //             question_status = 5;
                      //             completed_part_array.push(activityQuestionPart);
                      //           }else{
                      //            if(parseInt(active_state) === 1){
                      //            // console.log('active')
                      //               question_status = 1;
                      //               active_state = 0;
                      //               part_array.push(activityQuestionPart);
                      //               if(ActiveData == null){
                      //                 // setActiveData(activityQuestionPart);
                      //                 setActiveData(activity);
                      //               }
                      //             }else{
                      //               question_status = 2;
                      //             }
                      //           }
  
                      //           }else{
                      //             if(parseInt(active_state) === 1){
                      //               question_status = 1;
                      //               active_state = 0;
                                   
                      //                 setIsFirstQuestion(i);
                      //                   part_array.push(activityQuestionPart);
                      //                   if(ActiveData == null){
                      //                     // setActiveData(activityQuestionPart);
                      //                     setActiveData(activity);
                      //                   }
                      //             }else{
                      //               question_status = 2;
                      //             }
                                 
                      //           }
                                
                               
                      //         });
                             
                      //       }
                          
                      //       setIsLastQuestiont(i);
                      //       if(PartQuestion.length === 0){
                      //         setPartQuestion(part_array);
                              
                      //       }
                           
                      //       if(CompletedPartQuestion.length === 0){
                      //         setCompletedPartQuestion(completed_part_array);
                      //       }
                            
  
                            
                      //       return (
                      //         <NavItem key={index}>
                      //           <NavLink style={{ cursor: "pointer" }} className={ classnames({ active: customActiveTab=== index })
                      //             + "display-hold_active"} 
                      //             >
                      //              <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index}</span> 
                      //           </NavLink>
                      //         </NavItem>
                      //     )
  
                      // }
  
  
                     })
  
  
               
               
             // }
  
  
          }else if(activity.question &&
            activity.question.length > 0 &&
            parseInt(activity.fk_sub_id) === 2){
              //maths
              return activity.question.map(
                (question, index1) => {
                 // console.log('enter2')
                    if (
                      question.question_id != null 
                    ) {
                      
                      let filteredManualMark = [];
                      //let filteredManualMark = question.question_parts.filter(manualMark => ((parseInt(manualMark.english_question_part_automark) === 0))) 
                      let question_status = 1;
                      
                     // console.log(active_state)
                          if (
                            question.question_parts &&
                            question.question_parts.length > 0
                          ) {


                           //let total_coins = question.english_question_parts.length * 2;
                           //setTotalCoins(prevTotalCoins => prevTotalCoins + total_coins);
                           question.question_parts.forEach((activityQuestionPart, index2) => {
                            //let partManualMark = parseInt(activityQuestionPart.english_question_part_automark);
                            i++;
                            let total_mark = 0;
                              if (activityQuestionPart.StudentAnsAttempts && activityQuestionPart.StudentAnsAttempts.length > 0) {
                                
                                activityQuestionPart.StudentAnsAttempts.forEach((stuAnswer, index3) => {
                                  
                                  if (stuAnswer.points_obtained!=null) {
                                    total_mark += parseInt(stuAnswer.points_obtained); // Increment count_answer by 1 for each occurrence
                                  }
                                });

                              if(activityQuestionPart.StudentAnsAttempts.length >=2 && total_mark === 0){
                                question_status = 3;
                              }else if(activityQuestionPart.StudentAnsAttempts.length >=1 && total_mark >= 2){
                                question_status = 4;
                                
                              }else if(activityQuestionPart.StudentAnsAttempts.length >=2 && total_mark > 0){
                                question_status = 5;
                               
                              // }else if(activityQuestionPart.StudentAnsAttempts.length ===1 && total_mark >= 0 && partManualMark <= 0){
                              //   question_status = 2;
                               
                              }else{
                               if(parseInt(active_state) === 1){
                               // console.log('active')
                                  question_status = 1;
                                  active_state = 0;
                                  IsFirstQuestion.current = i;
                                  IsCurrentTab.current = index + 1;
                                  IsCurrentTabTotalPart.current= question.question_parts.length;
                                  IsCurrentTabCurrentPart.current= index2 +1;
                                     //setIsFirstQuestion(i);
                                      if(ActiveData == null){
                                        // setActiveData(activityQuestionPart);
                                        setActiveData(activity);
                                        setActivityPart([activityQuestionPart]);

                                      }
                                
                                }else{
                                  question_status = 2;
                                }
                              }

                              }else{
                                if(parseInt(active_state) === 1){
                                  question_status = 1;
                                  active_state = 0;
                                 
                                    // setIsFirstQuestion(i);
                                 IsFirstQuestion.current = i;
                                 IsCurrentTab.current = index + 1;
                                 IsCurrentTabTotalPart.current= question.question_parts.length;
                                 IsCurrentTabCurrentPart.current= index2 +1;
                                    if(ActiveData == null){
                                      // setActiveData(activityQuestionPart);
                                      setActiveData(activity);
                                      setActivityPart([activityQuestionPart]);
                                    }
                                }else{
                                  question_status = 2;
                                }
                               
                              }
                             // console.log('status -'+i+' - '+question_status);
                             
                            });
                           
                          }
                          IsLastQuestion.current = i;
                          //setIsLastQuestiont(i);
                         
                          return (
                            <NavItem key={index}>
                              {/* { parseInt(question.english_question_category_id) === 8 ?  




                                <NavLink style={{ cursor: "pointer" }} className={ classnames({ active: customActiveTab=== index+1 })+ " display-hold_active"}> 
                                  <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span>
                                </NavLink> 
                                :  */}
                              <NavLink style={{ cursor: "pointer" }} className={ classnames({ active: customActiveTab=== index+1 })
                                + IsCurrentTab.current == index+1 ? " display-hold_active" :
                                  question_status && question_status === 1 ? " display-hold_active" : 
                                  question_status &&  question_status === 2 ? " display-number" : 
                                  question_status && question_status === 3 ? " display-attempt_fail" :
                                  question_status && question_status === 4 ? " display-attempt1" :
                                  question_status && question_status === 5 ? " display-attempt2" :
                                   " display-number"} 
                                >
                                  {IsCurrentTab.current === index+1 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                  question_status && question_status === 1 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                 question_status && question_status === 2 ? <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span> : 
                                 question_status && question_status === 3 ? filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="mdi mdi-close-thick text-danger font-size-20"></i> :
                                 question_status && question_status === 4 ?  filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="fas fa-check text-success font-size-20"></i> :
                                 question_status && question_status === 5 ? filteredManualMark.length > 0 ? <span className="font-size-20 fw-bold">M</span> : <i className="fas fa-check text-warning font-size-20"></i> :
                                 <span className="font-size-20 fw-bold">{filteredManualMark.length > 0 ? 'M' : index+1}</span>}
                                
                              </NavLink>

                            {/* } */}



                            </NavItem>
                        )
  
                        }
  
  
                       
  
                      })
  
  
  
  
  
          }else{
            //else
          }
        })
        : null
  )     
  });

  export default Pagination