import React from "react";

import {
    Card,
    CardBody,
    Col,
    Row,
    Table,
    Container,
} from "reactstrap";
import { Link } from "react-router-dom";
// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FormEditors = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    {/* <Breadcrumbs maintitle="Veltrix" title="Forms" breadcrumbItem="Form Editors" /> */}
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <h6 className="page-title">All Notifications</h6>
                            </Col>


                        </Row>
                    </div>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>

                                    <div className="table-responsive">
                                        <Table className="table table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Questin Id</th>
                                                    <th>Student Name</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1854</th>
                                                    <td>Jimmy Carter</td>
                                                    <td>2nd Feburary 2024 (Wed)</td>
                                                    <td>09:34 AM</td>
                                                    <td><Link>View Details <i className="mdi mdi-arrow-right-thick"></i></Link></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FormEditors;
