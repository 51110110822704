import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";


import axios from "axios";
import moment from "moment";

import awicon from "../../../assets/images/services-icon/coin.png";
import { SECURITY_KEY, BASE_URL } from "../../../global";

const NotificationDropdown = props => {



const [modal_center, setmodal_center] = useState(false);
const [ReportData, setReportData] = useState([]);
const [ReportReadData, setReadReportData] = useState([]);

const tog_center = () => {
setmodal_center(!modal_center)
//removeBodyCss()
}
// Declare a new state variable, which we'll call "menu"
const [menu, setMenu] = useState(false)


const authUser = JSON.parse(localStorage.getItem('authStudentUser')) || null;

const navigate = useNavigate();


useEffect(() => {
  fetchReportData();
    // Set interval to run fetchData every one minute
    const interval = setInterval(fetchReportData, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
}, [props.success]);

const fetchReportData = async () => {
  if (authUser && authUser != "" && authUser != null) {
    
    axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
    axios.post(BASE_URL+'studenthome/ReportDetails',{
    }).then(responseReport => {
      // Assuming the API response is an array of objects with 'id' and 'value' properties
     // console.log(responseReport);
    if(responseReport.data.length > 0){
      setReportData(responseReport.data);
    }else{
      setReportData([]);
    }


    })
      .catch(error => {
       
        // console.log(error.response.data.error)
        if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
          localStorage.clear();
          navigate("/login");
        }

      });


  }
  else {
   
    navigate("/login");
  }

}


const readReportData = async () => {
  fetchReadReportData();
  tog_center();
 
}


const fetchReadReportData = async () => {
  if (authUser && authUser != "" && authUser != null) {
    
    axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
    axios.post(BASE_URL+'studenthome/ReportReadDetails',{
    }).then(responseReport => {
      // Assuming the API response is an array of objects with 'id' and 'value' properties
     // console.log(responseReport);
    if(responseReport.data.length > 0){
      setReadReportData(responseReport.data);
      setReportData([]);
    }else{
      setReadReportData([]);
      setReportData([]);
    }


    })
      .catch(error => {
       
        // console.log(error.response.data.error)
        if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
          localStorage.clear();
          navigate("/login");
        }

      });


  }
  else {
   
    navigate("/login");
  }

}


return (
<React.Fragment>
  <div className="btn header-item noti-icon waves-effect" onClick={() => readReportData()}>
    <i className="mdi mdi-bell-outline"></i>
    {ReportData && ReportData.length > 0 ? <span className="badge bg-white text-dark rounded-pill">{ReportData.length}</span> : null}
    
  </div>
  <Modal size="lg" isOpen={modal_center} toggle={tog_center} centered>
    <ModalHeader className="mt-0 p-0 pb-3 border-0" toggle={tog_center}>

    </ModalHeader>
    <ModalBody className="p-0">
      <h5 className="font-size-20 text-dark text-center fw-bold mb-3">
        Your Reports
      </h5>
      <div className="table-responsive mt-4" style={{ height: '500px'}}>
        <Table className="table custom-table noti-table">
          <thead>
            <tr>
              <th width="10%" className="">
                Question ID
              </th>
              <th width="30%" className="">
                Report Type
              </th>
              <th width="15%" className="">Reward</th>
              <th width="30%" className="">Flag Reason</th>
              <th width="25%" className="">
                Date
              </th>

            </tr>
          </thead>
          <tbody>
          {ReportReadData && ReportReadData.length > 0 ? (
            ReportReadData.map((report, index) => (
              <tr key={index}  className={parseInt(report.is_read) === 0 ? 'active' : ''}>
                <td>{report.question_id != null && report.question_id !== '' ? '#' + report.question_id : null}</td>
                <td>{parseInt(report.report_type) === 1 ? 'Question marked incorrect report' : 'Question flag report'}</td>
                <td className="text-center">{parseInt(report.rewards) === 0 ? <h6 className="text-center text-danger mb-0">Rejected</h6> : <>
                    <img className="rounded-circle obj-fit-cover me-1" src={awicon} width={24} alt="" />
                    {report.rewards}
                  </>}
                </td>
                <td>{report.flag_reason != null && report.flag_reason !== '' ? report.flag_reason : '-'}</td>
                <td>
                  {report.updated_on != null && report.updated_on !== '' ? moment(report.updated_on, moment.ISO_8601).format("DD-MM-YYYY") : null}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                <p className="font-size-15 opacity-50 text-center mb-0 py-1">No Reports Found</p>
              </td>
            </tr>
          )}
        </tbody>

          
          
        </Table>
        {/* <div className="d-block ">
            <p className="font-size-15 opacity-50 text-center mb-0 py-1">No Reports Found</p>
          </div> */}
      </div>
    </ModalBody>
  </Modal>
</React.Fragment>
)
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
t: PropTypes.any,
}