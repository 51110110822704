import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Container,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

import "react-multi-carousel/lib/styles.css"
import "react-multi-carousel/lib/styles.css"
import "react-slideshow-image/dist/styles.css"

import { Link, useNavigate } from "react-router-dom"
import classnames from "classnames"
import { Slide } from "react-slideshow-image"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//Common import images ===========================================================================

import awicon from "../../assets/images/services-icon/coin.png"
import us2 from "../../assets/images/services-icon/us2.png"
import us3 from "../../assets/images/services-icon/us3.png"
import us4 from "../../assets/images/services-icon/us4.png"
import us5 from "../../assets/images/services-icon/us5.png"
import us6 from "../../assets/images/services-icon/us6.png"
import us7 from "../../assets/images/services-icon/us7.png"
import us8 from "../../assets/images/services-icon/us8.png"

//userprofile order start
import usprofile1 from "../../assets/images/services-icon/us3.png"
import usprofile2 from "../../assets/images/services-icon/us4.png"
import usprofile3 from "../../assets/images/services-icon/us5.png"
import usprofile4 from "../../assets/images/services-icon/us6.png"
import usprofile5 from "../../assets/images/services-icon/us7.png"
import usprofile6 from "../../assets/images/services-icon/us8.png"
import jwtDecode from "jwt-decode"
//userprofile order end
// Pirate-male images import ====================================================================

// default-img

import piratemale from "../../assets/images/profile-avatars/avatar1/piratemale.png"
import { SECURITY_KEY, BASE_URL } from "../../global";
// male head-icons **********************************

import hd1 from "../../assets/images/profile-avatars/avatar1/head/hd1.png"
import hd2 from "../../assets/images/profile-avatars/avatar1/head/hd2.png"
import hd3 from "../../assets/images/profile-avatars/avatar1/head/hd3.png"
import hd4 from "../../assets/images/profile-avatars/avatar1/head/hd4.png"
import hd5 from "../../assets/images/profile-avatars/avatar1/head/hd5.png"
import hd6 from "../../assets/images/profile-avatars/avatar1/head/hd6.png"
import hd7 from "../../assets/images/profile-avatars/avatar1/head/hd7.png"
import hd8 from "../../assets/images/profile-avatars/avatar1/head/hd8.png"
import hd9 from "../../assets/images/profile-avatars/avatar1/head/hd9.png"
import hd10 from "../../assets/images/profile-avatars/avatar1/head/hd10.png"
import hd11 from "../../assets/images/profile-avatars/avatar1/head/hd11.png"

// dress-icons **********************************

import dr1 from "../../assets/images/profile-avatars/avatar1/dress/dr1.png"
import dr2 from "../../assets/images/profile-avatars/avatar1/dress/dr2.png"
import dr3 from "../../assets/images/profile-avatars/avatar1/dress/dr3.png"
import dr4 from "../../assets/images/profile-avatars/avatar1/dress/dr4.png"
import dr5 from "../../assets/images/profile-avatars/avatar1/dress/dr5.png"

// companion-icons **********************************

import cm1 from "../../assets/images/profile-avatars/avatar1/companion/cm1.png"
import cm2 from "../../assets/images/profile-avatars/avatar1/companion/cm2.png"
import cm3 from "../../assets/images/profile-avatars/avatar1/companion/cm3.png"
import cm4 from "../../assets/images/profile-avatars/avatar1/companion/cm4.png"
import cm5 from "../../assets/images/profile-avatars/avatar1/companion/cm5.png"

// weapon-icons **********************************

import wp1 from "../../assets/images/profile-avatars/avatar1/weapon/wp1.png"
import wp2 from "../../assets/images/profile-avatars/avatar1/weapon/wp2.png"
import wp3 from "../../assets/images/profile-avatars/avatar1/weapon/wp3.png"
import wp4 from "../../assets/images/profile-avatars/avatar1/weapon/wp4.png"
import wp5 from "../../assets/images/profile-avatars/avatar1/weapon/wp5.png"
import wp6 from "../../assets/images/profile-avatars/avatar1/weapon/wp6.png"
import wp7 from "../../assets/images/profile-avatars/avatar1/weapon/wp7.png"
import wp8 from "../../assets/images/profile-avatars/avatar1/weapon/wp8.png"
import wp9 from "../../assets/images/profile-avatars/avatar1/weapon/wp9.png"
import wp10 from "../../assets/images/profile-avatars/avatar1/weapon/wp10.png"

// hand-icons **********************************

import hnd1 from "../../assets/images/profile-avatars/avatar1/hand/hnd1.png"
import hnd2 from "../../assets/images/profile-avatars/avatar1/hand/hnd2.png"
import hnd3 from "../../assets/images/profile-avatars/avatar1/hand/hnd3.png"
import hnd4 from "../../assets/images/profile-avatars/avatar1/hand/hnd4.png"
import hnd5 from "../../assets/images/profile-avatars/avatar1/hand/hnd5.png"

// Pirate-female images import ====================================================================

// default-img

import piratefemale from "../../assets/images/profile-avatars/avatar2/piratefemale.png"

// female head-icons **********************************

import fhd1 from "../../assets/images/profile-avatars/avatar2/head/fhd1.png"
import fhd2 from "../../assets/images/profile-avatars/avatar2/head/fhd2.png"
import fhd3 from "../../assets/images/profile-avatars/avatar2/head/fhd3.png"
import fhd4 from "../../assets/images/profile-avatars/avatar2/head/fhd4.png"
import fhd5 from "../../assets/images/profile-avatars/avatar2/head/fhd5.png"
import fhd6 from "../../assets/images/profile-avatars/avatar2/head/fhd6.png"
import fhd7 from "../../assets/images/profile-avatars/avatar2/head/fhd7.png"
import fhd8 from "../../assets/images/profile-avatars/avatar2/head/fhd8.png"
import fhd9 from "../../assets/images/profile-avatars/avatar2/head/fhd9.png"
import fhd10 from "../../assets/images/profile-avatars/avatar2/head/fhd10.png"

//i18n
import { withTranslation } from "react-i18next"
import Loader from "react-spinner-loader"
//pass request back end
import axios from "axios"


//Boys user Profile
import b1 from "../../assets/images/users/boyuser/b1.png";
import b2 from "../../assets/images/users/boyuser/b2.png";
import b3 from "../../assets/images/users/boyuser/b3.png";
import b4 from "../../assets/images/users/boyuser/b4.png";
import b5 from "../../assets/images/users/boyuser/b5.png";
import b6 from "../../assets/images/users/boyuser/b6.png";
import b7 from "../../assets/images/users/boyuser/b7.png";
import b8 from "../../assets/images/users/boyuser/b8.png";
import b9 from "../../assets/images/users/boyuser/b9.png";
import b10 from "../../assets/images/users/boyuser/b10.png";

//girl user profile
import g1 from "../../assets/images/users/girluser/g1.png";
import g2 from "../../assets/images/users/girluser/g2.png";
import g3 from "../../assets/images/users/girluser/g3.png";
import g4 from "../../assets/images/users/girluser/g4.png";
import g5 from "../../assets/images/users/girluser/g5.png";
import g6 from "../../assets/images/users/girluser/g6.png";
import g7 from "../../assets/images/users/girluser/g7.png";
import g8 from "../../assets/images/users/girluser/g8.png";
import g9 from "../../assets/images/users/girluser/g9.png";
import g10 from "../../assets/images/users/girluser/g10.png";


const UserProfile = props => {
  const [userDetails, setuserDetails] = useState(null)

  //Dedoce user details start
  function decodeAccessToken(token) {
    try {
      const decoded = jwtDecode(token)
      return { success: true, data: decoded }
    } catch (error) {
      return { success: false, error: error.message }
    }
  }
  useEffect(() => {
    if (localStorage.getItem("authStudentUser")) {
      const obj = JSON.parse(localStorage.getItem("authStudentUser"))
      const jwtdecode = decodeAccessToken(obj)
      setuserDetails(jwtdecode.data)
      //}
    }
  }, [props.success])

  //edit profile image

  const [profileEdit, setEdit] = useState(false)

  const tog_editProfile = () => {
    setEdit(!profileEdit)
  }

  const [userAvatherSeleced, setAvatherSeleced] = useState(
    parseInt(localStorage.getItem("userprofile"))
  )
  const selectprofile = data => {
    setAvatherSeleced(data)
  }

  const [userProfileImg, setUserProfileimg] = useState(
    localStorage.getItem("userprofile") || null
  )
  const [userGender, setUserGender] = useState(
    localStorage.getItem("usergender") || null
  )
  const authUser = JSON.parse(localStorage.getItem("authStudentUser")) || null

  const navigate = useNavigate()

  const submitAvathor = async () => {
    if (authUser && authUser != "" && authUser != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(BASE_URL+"studentlogin/updateAvathor", {
          st_avatar: userAvatherSeleced,
        })
        .then(responseAvather => {
          if (responseAvather.data == "1") {
            tog_editProfile()
            localStorage.setItem("userprofile", userAvatherSeleced)
            setUserProfileimg(userAvatherSeleced)
          } else {
            toast.error("Not set profile image", {
              autoClose: 3000, // 3 seconds in this example
            })
          }
        })
        .catch(error => {
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }
  }

  const [customActiveTab, setcustomActiveTab] = useState("1")

  function toggleCustom(tab) {
    // console.log(tab);
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  //student avatar system

  //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'
  const [maleAvatarData, setMaleAvatarData] = useState([])
  const [femaleAvatarData, setFemaleAvatarData] = useState([])
  const [AvatarTabData, setAvatarTabData] = useState([])

  const [AvatarEquipped, setAvatarEquipped] = useState(null)
  const [loader, setLoader] = useState(false)

  //purchase start
  const [AvatarPurItem, setAvatarPurItem] = useState(null)
  const [modal_purchase, setmodal_purchase] = useState(false)
  const [isPurSubmitting, setIsPurSubmitting] = useState(false)
  //purchase end

  const [AvatarGendar, setAvatarGendar] = useState(false)

  const togAvatarGendar = () => {
    setAvatarGendar(!AvatarGendar)
  }

  const properties = {
    prevArrow: (
      <button className="prev-btn">
        <i className="fas fa-arrow-circle-left"></i>
      </button>
    ),
    nextArrow: (
      <button className="next-btn">
        <i className="fas fa-arrow-circle-right"></i>
      </button>
    ),
  }

  //resource male
  const [maleAvatarHeadData, setMaleAvatarHeadData] = useState({})
  const [maleAvatarArmourData, setMaleAvatarArmourData] = useState({})
  const [maleAvatarHandData, setMaleAvatarHandData] = useState({})
  const [maleAvatarWeaponData, setMaleAvatarWeaponData] = useState({})
  const [maleAvatarPetData, setMaleAvatarPetData] = useState({})

  //resource female
  const [femaleAvatarHeadData, setFemaleAvatarHeadData] = useState({})
  const [femaleAvatarArmourData, setFemaleAvatarArmourData] = useState({})
  const [femaleAvatarHandData, setFemaleAvatarHandData] = useState({})
  const [femaleAvatarWeaponData, setFemaleAvatarWeaponData] = useState({})
  const [femaleAvatarPetData, setFemaleAvatarPetData] = useState({})

  const fetchStudentAvatarData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(
          BASE_URL+"studentAvatar/StudentAvatarDeatils",
          {}
        )
        .then(responseAvatar => {
          if (responseAvatar.data != "") {
            // console.log(responseAvatar.data);
            if (responseAvatar.data.maleInventory.length > 0) {
              setMaleAvatarData(responseAvatar.data.maleInventory)

              //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'

              const filteredMaleHeadArray =
                responseAvatar.data.maleInventory.filter(
                  item => parseInt(item.resource_type) === 0
                )
              if (filteredMaleHeadArray.length > 0) {
                setMaleAvatarHeadData(filteredMaleHeadArray[0])
              } else {
                setMaleAvatarHeadData({})
              }

              const filteredMaleArmourArray =
                responseAvatar.data.maleInventory.filter(
                  item => parseInt(item.resource_type) === 1
                )
              if (filteredMaleArmourArray.length > 0) {
                setMaleAvatarArmourData(filteredMaleArmourArray[0])
              } else {
                setMaleAvatarArmourData({})
              }

              const filteredMaleHandArray =
                responseAvatar.data.maleInventory.filter(
                  item => parseInt(item.resource_type) === 2
                )
              if (filteredMaleHandArray.length > 0) {
                setMaleAvatarHandData(filteredMaleHandArray[0])
              } else {
                setMaleAvatarHandData({})
              }

              const filteredMaleWeaponArray =
                responseAvatar.data.maleInventory.filter(
                  item => parseInt(item.resource_type) === 3
                )
              if (filteredMaleWeaponArray.length > 0) {
                setMaleAvatarWeaponData(filteredMaleWeaponArray[0])
              } else {
                setMaleAvatarWeaponData({})
              }

              const filteredMalePetArray =
                responseAvatar.data.maleInventory.filter(
                  item => parseInt(item.resource_type) === 4
                )
              if (filteredMalePetArray.length > 0) {
                setMaleAvatarPetData(filteredMalePetArray[0])
              } else {
                setMaleAvatarPetData({})
              }
            } else {
              setMaleAvatarData([])
              setMaleAvatarHeadData({})
              setMaleAvatarArmourData({})
              setMaleAvatarHandData({})
              setMaleAvatarWeaponData({})
              setMaleAvatarPetData({})
            }

            if (responseAvatar.data.femaleInventory.length > 0) {
              setFemaleAvatarData(responseAvatar.data.femaleInventory)
              //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'

              const filteredFeMaleHeadArray =
                responseAvatar.data.femaleInventory.filter(
                  item => parseInt(item.resource_type) === 0
                )
              if (filteredFeMaleHeadArray.length > 0) {
                setFemaleAvatarHeadData(filteredFeMaleHeadArray[0])
              } else {
                setFemaleAvatarHeadData({})
              }

              const filteredFeMaleArmourArray =
                responseAvatar.data.femaleInventory.filter(
                  item => parseInt(item.resource_type) === 1
                )
              if (filteredFeMaleArmourArray.length > 0) {
                setFemaleAvatarArmourData(filteredFeMaleArmourArray[0])
              } else {
                setFemaleAvatarArmourData({})
              }

              const filteredFeMaleHandArray =
                responseAvatar.data.femaleInventory.filter(
                  item => parseInt(item.resource_type) === 2
                )
              if (filteredFeMaleHandArray.length > 0) {
                setFemaleAvatarHandData(filteredFeMaleHandArray[0])
              } else {
                setFemaleAvatarHandData({})
              }

              const filteredFeMaleWeaponArray =
                responseAvatar.data.femaleInventory.filter(
                  item => parseInt(item.resource_type) === 3
                )
              if (filteredFeMaleWeaponArray.length > 0) {
                setFemaleAvatarWeaponData(filteredFeMaleWeaponArray[0])
              } else {
                setFemaleAvatarWeaponData({})
              }

              const filteredFeMalePetArray =
                responseAvatar.data.femaleInventory.filter(
                  item => parseInt(item.resource_type) === 4
                )
              if (filteredFeMalePetArray.length > 0) {
                setFemaleAvatarPetData(filteredFeMalePetArray[0])
              } else {
                setFemaleAvatarPetData({})
              }
            } else {
              setFemaleAvatarData([])
              setFemaleAvatarHeadData({})
              setFemaleAvatarArmourData({})
              setFemaleAvatarHandData({})
              setFemaleAvatarWeaponData({})
              setFemaleAvatarPetData({})
            }
          } else {
            setMaleAvatarData([])
            setMaleAvatarHeadData({})
            setMaleAvatarArmourData({})
            setMaleAvatarHandData({})
            setMaleAvatarWeaponData({})
            setMaleAvatarPetData({})

            setFemaleAvatarData([])
            setFemaleAvatarHeadData({})
            setFemaleAvatarArmourData({})
            setFemaleAvatarHandData({})
            setFemaleAvatarWeaponData({})
            setFemaleAvatarPetData({})
          }
        })
        .catch(error => {
          // console.log(error.response.data.error)
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }
  }

  const fetchStudentAvatarTabData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      setLoader(true)
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(
          BASE_URL+"studentAvatar/StudentAvatarTabDeatils",
          {
            gender: AvatarGendar,
            activeTab: customActiveTab,
          }
        )
        .then(responseAvatar => {
          setLoader(false)
          if (responseAvatar.data.length > 0) {
            setAvatarTabData(responseAvatar.data)
            //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'
          } else {
            setAvatarTabData([])
            //No data
          }
        })
        .catch(error => {
          setLoader(false)
          // console.log(error.response.data.error)
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      setLoader(false)
      navigate("/login")
    }
  }

  const StudentAvatarItemEquipped = async () => {
    if (authUser && authUser != "" && authUser != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(
          BASE_URL+"studentAvatar/StudentAvatarItemEquipped",
          {
            pk_supply_key: AvatarEquipped.pk_supply_key,
            resource_type: AvatarEquipped.resource_type,
            character_type: AvatarEquipped.character_type,
          }
        )
        .then(responseAvatarEquipped => {
          if (responseAvatarEquipped.data == "1") {
            fetchStudentAvatarData()
            fetchStudentAvatarTabData()
            setAvatarEquipped(null)
            toast.success("Equipped successfully.", {
              autoClose: 3000, // 3 seconds in this example
            })
            //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'
          } else {
            toast.error("Sorry, Not equipped this item.", {
              autoClose: 3000, // 3 seconds in this example
            })
            //No data
          }
        })
        .catch(error => {
          // console.log(error.response.data.error)
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      navigate("/login")
    }
  }

  const onPurchaseItem = async () => {
    if (authUser && authUser != "" && authUser != null) {
      setAvatarPurItem(true)
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      axios
        .post(
          BASE_URL+"studentAvatar/StudentAvatarItemPurchased",
          {
            pk_supply_key: AvatarPurItem.pk_supply_key,
            resource_type: AvatarPurItem.resource_type,
            character_type: AvatarPurItem.character_type,
            coins: AvatarPurItem.coins,
          }
        )
        .then(responseAvatarPurchased => {
          setmodal_purchase(false)
          setAvatarPurItem(false)
          if (responseAvatarPurchased.data == "1") {
            setmodal_purchase(false)
            fetchStudentAvatarData()
            fetchStudentAvatarTabData()
            setAvatarPurItem(null)
            toast.success("You successfully purchased this item.", {
              autoClose: 3000, // 3 seconds in this example
            })
            //Note resource type : '0- Head, 1- Armour, 2- Hand, 3- Weapon, 4- Pet'
          } else if (responseAvatarPurchased.data == "2") {
            // tog_purchase();
            setmodal_purchase(false)
            toast.error("You don't have enough coins to purchase this item!", {
              autoClose: 3000, // 3 seconds in this example
            })
          } else {
            // tog_purchase();
            setmodal_purchase(false)
            toast.error("Sorry, Not purchase this item!", {
              autoClose: 3000, // 3 seconds in this example
            })
            //No data
          }
        })
        .catch(error => {
          setmodal_purchase(false)
          setAvatarPurItem(false)
          //tog_purchase();
          // console.log(error.response.data.error)
          if (
            error.response.status === 403 &&
            error.response.data.error === "jwt expired"
          ) {
            localStorage.clear()
            navigate("/login")
          }
        })
    } else {
      setAvatarPurItem(false)
      navigate("/login")
    }
  }

  useEffect(() => {
    fetchStudentAvatarData()
  }, [])

  useEffect(() => {
    setAvatarTabData([])
    fetchStudentAvatarTabData()
  }, [customActiveTab, AvatarGendar])

  const item_equipped = item => {
    setAvatarEquipped(item)
  }

  useEffect(() => {
    if (
      AvatarEquipped &&
      AvatarEquipped.pk_supply_key &&
      AvatarEquipped.pk_supply_key != ""
    ) {
      StudentAvatarItemEquipped()
    }
  }, [AvatarEquipped])

  const tog_purchase = () => {
    setmodal_purchase(!modal_purchase)
  }

  const item_purchased = item => {
    setAvatarPurItem(item)
    tog_purchase()
  }

  // Function to add "character_" prefix to filename
  const modifyUrl = url => {
    const parts = url.split("/")
    const filename = parts[parts.length - 1]
    const modifiedFilename = `character_${filename}`
    parts[parts.length - 1] = modifiedFilename
    return parts.join("/")
  }

  // student avatar system
  const boyImages = [{image:b1, text:'Astronaut'}, {image:b2, text:'Chemist'}, {image:b3, text:'Doctor'}, {image:b4, text:'Engineer'}, {image:b5, text:'Entrepreneur'},
  {image:b6, text:'Influencer'}, {image:b7, text:'Politician'}, {image:b8, text:'Programmer'}, {image:b9, text:'Scientist'}, {image:b10, text:'Teacher'}];
  const girlImages = [{image:g1, text:'Astronaut'}, {image:g2, text:'Chemist'}, {image:g3, text:'Doctor'}, {image:g4, text:'Engineer'}, {image:g5, text:'Entrepreneur'},
   {image:g6, text:'Influencer'}, {image:g7, text:'Politician'}, {image:g8, text:'Programmer'}, {image:g9, text:'Scientist'}, {image:g10, text:'Teacher'}];
  return (
    <React.Fragment>
      <ToastContainer autoClose={3000} />
      {/* popup for purched start*/}
      <Modal isOpen={modal_purchase} toggle={tog_purchase} centered>
        {/* <ModalHeader className="mt-0" toggle={tog_center}>Center Modal</ModalHeader> */}
        <ModalBody className="text-center">
          <div className="my-4">
            <h4 className="mb-4">
              {" "}
              <strong>
                Exchange{" "}
                {AvatarPurItem && AvatarPurItem.coins ? AvatarPurItem.coins : 0}{" "}
                coins for this item?
              </strong>
            </h4>

            <Button
              color="success"
              className="me-3"
              disabled={isPurSubmitting}
              onClick={() => onPurchaseItem()}
            >
              {" "}
              YES{" "}
            </Button>
            <Button color="danger" onClick={tog_purchase}>
              {" "}
              NO{" "}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {/* popup for purched  end*/}
      <div className="page-wrapper">
        <div className="cs-pg-header bg-dark-blue py-3 pb-5">
          <Container className="custom-wt">
            <div className="">
              <div className="mt-0">
                <Link className="text-white font-size-14" to={"/"}>
                  {" "}
                  <span>
                    <i className=" fas fa-chevron-left me-1"></i>
                  </span>{" "}
                  BACK TO DASHBOARD
                </Link>
              </div>
              <div className="pg-title">Account Settings</div>
            </div>
          </Container>
        </div>

        <Container>
          <div className="super-profile_wrapper">
            <div className="cs-card content-left">
              <div className="super-avatar-display">
                <Slide
                  autoplay={false}
                  infinite={false}
                  onStartChange={togAvatarGendar}
                  {...properties}
                >
                  {/* male functionality start */}
                  <div className="each-slide-effect">
                    <div className="pirate-media male position-relative">
                      <div className="avatar-display text-center position-relative">
                        <div className="avatar-default">
                          <img
                            width={450}
                            className=""
                            src={piratemale}
                            alt=""
                          />
                        </div>
                        <div className="avatar-attachments">
                          {maleAvatarHeadData &&
                          maleAvatarHeadData != "" &&
                          maleAvatarHeadData.image ? (
                            <div className="head-float position-absolute">
                              <img
                                className=""
                                src={modifyUrl(maleAvatarHeadData.image)}
                                alt=""
                              />
                            </div>
                          ) : null}

                          {maleAvatarArmourData &&
                          maleAvatarArmourData != "" &&
                          maleAvatarArmourData.image ? (
                            <div className="dress-float position-absolute">
                              <img
                                className=""
                                src={maleAvatarArmourData.image}
                                alt=""
                              />
                            </div>
                          ) : null}

                          {maleAvatarPetData &&
                          maleAvatarPetData != "" &&
                          maleAvatarPetData.image ? (
                            <div className="companion-float position-absolute">
                              <img
                                className=""
                                src={maleAvatarPetData.image}
                                alt=""
                              />
                            </div>
                          ) : null}

                          {maleAvatarHandData &&
                          maleAvatarHandData != "" &&
                          maleAvatarHandData.image ? (
                            <>
                              <div className="hand-float position-absolute">
                                <img
                                  className=""
                                  src={maleAvatarHandData.image}
                                  alt=""
                                />
                              </div>

                              <div className="hand-float-revert position-absolute">
                                <img
                                  className=""
                                  src={maleAvatarHandData.image}
                                  alt=""
                                />
                              </div>
                            </>
                          ) : null}

                          {maleAvatarWeaponData &&
                          maleAvatarWeaponData != "" &&
                          maleAvatarWeaponData.image ? (
                            <div className="weapon-float position-absolute">
                              <img
                                className=""
                                src={maleAvatarWeaponData.image}
                                alt=""
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* male functionality end */}

                  {/* Female functionality start */}
                  <div className="each-slide-effect">
                    <div className="pirate-media female position-relative">
                      <div className="avatar-display text-center position-relative">
                        <div className="avatar-default">
                          <img
                            width={450}
                            className=""
                            src={piratefemale}
                            alt=""
                          />
                        </div>
                        <div className="avatar-attachments">
                          {femaleAvatarHeadData &&
                          femaleAvatarHeadData != "" &&
                          femaleAvatarHeadData.image ? (
                            <div className="head-float female-hd position-absolute">
                              <img
                                className=""
                                src={modifyUrl(femaleAvatarHeadData.image)}
                                alt=""
                              />
                            </div>
                          ) : null}

                          {femaleAvatarArmourData &&
                          femaleAvatarArmourData != "" &&
                          femaleAvatarArmourData.image ? (
                            <div className="dress-float position-absolute">
                              <img
                                className=""
                                src={femaleAvatarArmourData.image}
                                alt=""
                              />
                            </div>
                          ) : null}

                          {femaleAvatarPetData &&
                          femaleAvatarPetData != "" &&
                          femaleAvatarPetData.image ? (
                            <div className="companion-float position-absolute">
                              <img
                                className=""
                                src={femaleAvatarPetData.image}
                                alt=""
                              />
                            </div>
                          ) : null}

                          {femaleAvatarHandData &&
                          femaleAvatarHandData != "" &&
                          femaleAvatarHandData.image ? (
                            <>
                              <div className="hand-float position-absolute">
                                <img
                                  className=""
                                  src={femaleAvatarHandData.image}
                                  alt=""
                                />
                              </div>
                              <div className="hand-float-revert position-absolute">
                                <img
                                  className=""
                                  src={femaleAvatarHandData.image}
                                  alt=""
                                />
                              </div>
                            </>
                          ) : null}

                          {femaleAvatarWeaponData &&
                          femaleAvatarWeaponData != "" &&
                          femaleAvatarWeaponData.image ? (
                            <div className="weapon-float position-absolute">
                              <img
                                className=""
                                src={femaleAvatarWeaponData.image}
                                alt=""
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Female functionality end */}
                </Slide>
              </div>
            </div>
            <div className="content-right">
              <div className="avatar-display-info">
                <div className="cs-card">
                  {/* <div className="d-flex align-items-center">
                    <h4 className="mb-0 font-size-20 fw-bold w-30">Username</h4>
                   
                  </div> */}
                  <div className="d-flex ">
                    {!profileEdit ? (
                      <div className="d-flex align-items-center flex-grow-1">
                        <img
                          width={40}
                          height={40}
                          className="rounded-circle obj-fit-cover me-2"
                          src={
                            userProfileImg && userGender
                              ? 
                              userGender === 'Male' ? 
                                parseInt(userProfileImg) === 1
                                ? b1
                                : parseInt(userProfileImg) === 2
                                ? b2
                                : parseInt(userProfileImg) === 3
                                ? b3
                                : parseInt(userProfileImg) === 4
                                ? b4
                                : parseInt(userProfileImg) === 5
                                ? b5
                                : parseInt(userProfileImg) === 6
                                ? b6
                                : parseInt(userProfileImg) === 7
                                ? b7
                                : parseInt(userProfileImg) === 8
                                ? b8
                                : parseInt(userProfileImg) === 9
                                ? b9
                                : parseInt(userProfileImg) === 10
                                ? b10 : b1
                              : userGender === 'Female' ? 
                                  parseInt(userProfileImg) === 1
                                  ? g1
                                  : parseInt(userProfileImg) === 2
                                  ? g2
                                  : parseInt(userProfileImg) === 3
                                  ? g3
                                  : parseInt(userProfileImg) === 4
                                  ? g4
                                  : parseInt(userProfileImg) === 5
                                  ? g5
                                  : parseInt(userProfileImg) === 6
                                  ? g6
                                  : parseInt(userProfileImg) === 7
                                  ? g7
                                  : parseInt(userProfileImg) === 8
                                  ? g8
                                  : parseInt(userProfileImg) === 9
                                  ? g9
                                  : parseInt(userProfileImg) === 10
                                  ? g10 : g1
                                : 
                                parseInt(userProfileImg) === 1
                                ? b1
                                : parseInt(userProfileImg) === 2
                                ? b2
                                : parseInt(userProfileImg) === 3
                                ? b3
                                : parseInt(userProfileImg) === 4
                                ? b4
                                : parseInt(userProfileImg) === 5
                                ? b5
                                : parseInt(userProfileImg) === 6
                                ? b6
                                : parseInt(userProfileImg) === 7
                                ? b7
                                : parseInt(userProfileImg) === 8
                                ? b8
                                : parseInt(userProfileImg) === 9
                                ? b9
                                : parseInt(userProfileImg) === 10
                                ? b10 : b1
                              : b1
                          }
                          alt=""
                        />
                        <div>
                          <p className="mb-0 font-size-18">
                            {userDetails
                              ? userDetails.st_first_name
                                ? userDetails.st_first_name +
                                  " " +
                                  userDetails.st_surname
                                : ""
                              : ""}
                          </p>
                          <Link
                            to={"#"}
                            className="text-danger fw-bold"
                            onClick={tog_editProfile}
                          >
                            EDIT
                          </Link>
                        </div>
                      </div>
                    ) : null}
                    {profileEdit ? (
                      <div className="media-list">
                        <div className="image-display-wrapper">
                          <ul className="list-unstyled mb-0">
                          {userGender && userGender === 'Male' ? 
                    
                    boyImages.map((boyData, index) => (
                      <li
                        key={index}
                        className={
                          userAvatherSeleced && userAvatherSeleced === index + 1
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(index + 1)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={boyData.image} // Assuming your image URLs follow this pattern
                          alt=""
                        />
                        <p class="text-center font-size-16 mb-1"><strong>{boyData.text}</strong></p>
                      </li>
                    ))
                  : userGender && userGender === 'Female' ?
                    girlImages.map((girlData, index) => (
                      <li
                        key={index}
                        className={
                          userAvatherSeleced && userAvatherSeleced === index + 1
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(index + 1)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={girlData.image} // Assuming your image URLs follow this pattern
                          alt=""
                        />
                        <p class="text-center font-size-16 mb-1"><strong>{girlData.text}</strong></p>
                      </li>
                    )) 
                    : boyImages.map((boyData, index) => (
                      <li
                        key={index}
                        className={
                          userAvatherSeleced && userAvatherSeleced === index + 1
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(index + 1)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={boyData.image} // Assuming your image URLs follow this pattern
                          alt=""
                        />
                        <p class="text-center font-size-16 mb-1"><strong>{boyData.text}</strong></p>
                      </li>
                    ))
                    
                    }
                            {/* <li
                              className={
                                userAvatherSeleced && userAvatherSeleced === 1
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => selectprofile(1)}
                            >
                              <img
                                width={94}
                                height={94}
                                className="rounded-circle obj-fit-cover"
                                src={usprofile1}
                                alt=""
                              />
                            </li>
                            <li
                              className={
                                userAvatherSeleced && userAvatherSeleced === 2
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => selectprofile(2)}
                            >
                              <img
                                width={94}
                                height={94}
                                className="rounded-circle obj-fit-cover"
                                src={usprofile2}
                                alt=""
                              />
                            </li>
                            <li
                              className={
                                userAvatherSeleced && userAvatherSeleced === 3
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => selectprofile(3)}
                            >
                              <img
                                width={94}
                                height={94}
                                className="rounded-circle obj-fit-cover"
                                src={usprofile3}
                                alt=""
                              />
                            </li>
                            <li
                              className={
                                userAvatherSeleced && userAvatherSeleced === 4
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => selectprofile(4)}
                            >
                              <img
                                width={94}
                                height={94}
                                className="rounded-circle obj-fit-cover"
                                src={usprofile4}
                                alt=""
                              />
                            </li>
                            <li
                              className={
                                userAvatherSeleced && userAvatherSeleced === 5
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => selectprofile(5)}
                            >
                              <img
                                width={94}
                                height={94}
                                className="rounded-circle obj-fit-cover"
                                src={usprofile5}
                                alt=""
                              />
                            </li>
                            <li
                              className={
                                userAvatherSeleced && userAvatherSeleced === 6
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => selectprofile(6)}
                            >
                              <img
                                width={94}
                                height={94}
                                className="rounded-circle obj-fit-cover"
                                src={usprofile6}
                                alt=""
                              />
                            </li> */}
                          </ul>
                        </div>

                        <div className="cta mt-4">
                          <Button
                            className="btn-cst-secondary-md me-3 px-3 font-size-14 fw-medium"
                            onClick={tog_editProfile}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btn-cst-success-md font-size-14 fw-medium"
                            onClick={() => submitAvathor()}
                            disabled={
                              userAvatherSeleced && userAvatherSeleced !== null
                                ? false
                                : true
                            }
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="cs-card">
                {/* male tabs navigator */}
                {!AvatarGendar ? (
                  <>
                    {" "}
                    <Nav tabs className="profile-tab mb-3 position-relative">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            classnames({ active: customActiveTab === "1" }) +
                            " set-postion-abs profile-tb-ps-1"
                          }
                          onClick={() => {
                            toggleCustom("1")
                          }}
                        >
                          <span className="">
                            {maleAvatarHeadData &&
                            maleAvatarHeadData != "" &&
                            maleAvatarHeadData.image ? (
                              <img
                                width={30}
                                height={30}
                                className=""
                                src={maleAvatarHeadData.image}
                                alt=""
                              />
                            ) : (
                              <i className="mdi mdi-crown h2"></i>
                            )}
                            {/* <img width={30} height={30} className="" src={hd1} alt="" /> */}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            classnames({ active: customActiveTab === "2" }) +
                            " set-postion-abs profile-tb-ps-2"
                          }
                          onClick={() => {
                            toggleCustom("2")
                          }}
                        >
                          <span className="">
                            {maleAvatarArmourData &&
                            maleAvatarArmourData != "" &&
                            maleAvatarArmourData.image ? (
                              <img
                                width={30}
                                height={30}
                                className=""
                                src={maleAvatarArmourData.image}
                                alt=""
                              />
                            ) : (
                              <i className="mdi mdi-tshirt-crew h2"></i>
                            )}
                            {/* <img width={30} height={30} className="" src={dr2} alt="" /> */}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            classnames({ active: customActiveTab === "3" }) +
                            " set-postion-abs profile-tb-ps-3"
                          }
                          onClick={() => {
                            toggleCustom("3")
                          }}
                        >
                          <span className="">
                            {maleAvatarPetData &&
                            maleAvatarPetData != "" &&
                            maleAvatarPetData.image ? (
                              <img
                                width={30}
                                height={30}
                                className=""
                                src={maleAvatarPetData.image}
                                alt=""
                              />
                            ) : (
                              <i className="mdi mdi-bird h2"></i>
                            )}
                            {/* <img width={30} height={30} className="" src={cm1} alt="" /> */}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            classnames({ active: customActiveTab === "5" }) +
                            " set-postion-abs profile-tb-ps-4"
                          }
                          onClick={() => {
                            toggleCustom("5")
                          }}
                        >
                          <span className="">
                            {maleAvatarHandData &&
                            maleAvatarHandData != "" &&
                            maleAvatarHandData.image ? (
                              <img
                                width={30}
                                height={30}
                                className=""
                                src={maleAvatarHandData.image}
                                alt=""
                              />
                            ) : (
                              <i className="mdi mdi-hand-left h2"></i>
                            )}
                            {/* <img width={30} height={30} className="" src={hnd1} alt="" /> */}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            classnames({ active: customActiveTab === "4" }) +
                            " set-postion-abs profile-tb-ps-5"
                          }
                          onClick={() => {
                            toggleCustom("4")
                          }}
                        >
                          <span className="d-none d-sm-block">
                            {maleAvatarWeaponData &&
                            maleAvatarWeaponData != "" &&
                            maleAvatarWeaponData.image ? (
                              <img
                                width={30}
                                height={30}
                                className=""
                                src={maleAvatarWeaponData.image}
                                alt=""
                              />
                            ) : (
                              <i className="mdi mdi-axe h2"></i>
                            )}
                            {/* <img width={30} height={30} className="" src={wp1} alt="" /> */}
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </>
                ) : (
                  <>
                    <Nav tabs className="profile-tab mb-4 position-relative">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            classnames({ active: customActiveTab === "1" }) +
                            " set-postion-abs profile-tb-ps-1"
                          }
                          onClick={() => {
                            toggleCustom("1")
                          }}
                        >
                          <span className="">
                            {femaleAvatarHeadData &&
                            femaleAvatarHeadData != "" &&
                            femaleAvatarHeadData.image ? (
                              <img
                                width={30}
                                height={30}
                                className=""
                                src={femaleAvatarHeadData.image}
                                alt=""
                              />
                            ) : (
                              <i className="mdi mdi-crown h2"></i>
                            )}
                            {/* <img width={30} height={30} className="" src={hd1} alt="" /> */}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            classnames({ active: customActiveTab === "2" }) +
                            " set-postion-abs profile-tb-ps-2"
                          }
                          onClick={() => {
                            toggleCustom("2")
                          }}
                        >
                          <span className="">
                            {femaleAvatarArmourData &&
                            femaleAvatarArmourData != "" &&
                            femaleAvatarArmourData.image ? (
                              <img
                                width={30}
                                height={30}
                                className=""
                                src={femaleAvatarArmourData.image}
                                alt=""
                              />
                            ) : (
                              <i className="mdi mdi-tshirt-crew h2"></i>
                            )}
                            {/* <img width={30} height={30} className="" src={dr2} alt="" /> */}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            classnames({ active: customActiveTab === "3" }) +
                            " set-postion-abs profile-tb-ps-3"
                          }
                          onClick={() => {
                            toggleCustom("3")
                          }}
                        >
                          <span className="">
                            {femaleAvatarPetData &&
                            femaleAvatarPetData != "" &&
                            femaleAvatarPetData.image ? (
                              <img
                                width={30}
                                height={30}
                                className=""
                                src={femaleAvatarPetData.image}
                                alt=""
                              />
                            ) : (
                              <i className="mdi mdi-bird h2"></i>
                            )}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            classnames({ active: customActiveTab === "5" }) +
                            " set-postion-abs profile-tb-ps-4"
                          }
                          onClick={() => {
                            toggleCustom("5")
                          }}
                        >
                          <span className="">
                            {femaleAvatarHandData &&
                            femaleAvatarHandData != "" &&
                            femaleAvatarHandData.image ? (
                              <img
                                width={30}
                                height={30}
                                className=""
                                src={femaleAvatarHandData.image}
                                alt=""
                              />
                            ) : (
                              <i className="mdi mdi-hand-left h2"></i>
                            )}
                            {/* <img width={30} height={30} className="" src={hnd1} alt="" /> */}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={
                            classnames({ active: customActiveTab === "4" }) +
                            " set-postion-abs profile-tb-ps-5"
                          }
                          onClick={() => {
                            toggleCustom("4")
                          }}
                        >
                          <span className="d-none d-sm-block">
                            {femaleAvatarWeaponData &&
                            femaleAvatarWeaponData != "" &&
                            femaleAvatarWeaponData.image ? (
                              <img
                                width={30}
                                height={30}
                                className=""
                                src={femaleAvatarWeaponData.image}
                                alt=""
                              />
                            ) : (
                              <i className="mdi mdi-axe h2"></i>
                            )}
                            {/* <img width={30} height={30} className="" src={wp1} alt="" /> */}
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </>
                )}
                <TabContent
                  activeTab={customActiveTab}
                  className="profile-utlities"
                >
                  <div>
                    {" "}
                    <Loader
                      show={loader}
                      type="inline"
                      // stack="vertical"
                      // message="Loading Data"
                    />
                  </div>
                  <TabPane tabId="1">
                    <div className="profile-utlities-wrapper head">
                      <ul className="mb-0 list-unstyled profile-utlities">
                        {AvatarTabData && AvatarTabData.length > 0 ? (
                          AvatarTabData.map((item, index) => (
                            <li key={index}>
                              <img
                                width={68}
                                className=""
                                src={item.image}
                                alt=""
                              />
                              {item.is_equipped &&
                              parseInt(item.is_equipped) === 1 ? (
                                <Button className="btn-avt-selected">
                                  Selected
                                </Button>
                              ) : parseInt(item.is_equipped) === 0 ? (
                                <Button
                                  className="btn-avt-select"
                                  onClick={() => item_equipped(item)}
                                >
                                  Select
                                </Button>
                              ) : (
                                <Button
                                  className="btn-avt-purchase"
                                  onClick={() => item_purchased(item)}
                                >
                                  <img
                                    width={15}
                                    className=""
                                    src={awicon}
                                    alt=""
                                  />{" "}
                                  {item.coins}
                                </Button>
                              )}
                            </li>
                          ))
                        ) : (
                          <p className="text-center">No data yet</p>
                        )}
                      </ul>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="profile-utlities-wrapper dress">
                      <ul className="mb-0 list-unstyled profile-utlities">
                        {AvatarTabData && AvatarTabData.length > 0 ? (
                          AvatarTabData.map((item, index) => (
                            <li key={index}>
                              <img
                                width={68}
                                className=""
                                src={item.image}
                                alt=""
                              />
                              {item.is_equipped &&
                              parseInt(item.is_equipped) === 1 ? (
                                <Button className="btn-avt-selected">
                                  Selected
                                </Button>
                              ) : parseInt(item.is_equipped) === 0 ? (
                                <Button
                                  className="btn-avt-select"
                                  onClick={() => item_equipped(item)}
                                >
                                  Select
                                </Button>
                              ) : (
                                <Button
                                  className="btn-avt-purchase"
                                  onClick={() => item_purchased(item)}
                                >
                                  <img
                                    width={15}
                                    className=""
                                    src={awicon}
                                    alt=""
                                  />{" "}
                                  {item.coins}
                                </Button>
                              )}
                            </li>
                          ))
                        ) : (
                          <p className="text-center">No data yet</p>
                        )}
                      </ul>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="profile-utlities-wrapper companion">
                      <ul className="mb-0 list-unstyled profile-utlities">
                        {AvatarTabData && AvatarTabData.length > 0 ? (
                          AvatarTabData.map((item, index) => (
                            <li key={index}>
                              <img
                                width={68}
                                className=""
                                src={item.image}
                                alt=""
                              />
                              {item.is_equipped &&
                              parseInt(item.is_equipped) === 1 ? (
                                <Button className="btn-avt-selected">
                                  Selected
                                </Button>
                              ) : parseInt(item.is_equipped) === 0 ? (
                                <Button
                                  className="btn-avt-select"
                                  onClick={() => item_equipped(item)}
                                >
                                  Select
                                </Button>
                              ) : (
                                <Button
                                  className="btn-avt-purchase"
                                  onClick={() => item_purchased(item)}
                                >
                                  <img
                                    width={15}
                                    className=""
                                    src={awicon}
                                    alt=""
                                  />{" "}
                                  {item.coins}
                                </Button>
                              )}
                            </li>
                          ))
                        ) : (
                          <p className="text-center">No data yet</p>
                        )}
                      </ul>
                    </div>
                  </TabPane>
                  <TabPane tabId="4">
                    <div className="profile-utlities-wrapper weapon">
                      <ul className="mb-0 list-unstyled profile-utlities">
                        {AvatarTabData && AvatarTabData.length > 0 ? (
                          AvatarTabData.map((item, index) => (
                            <li key={index}>
                              <img
                                width={68}
                                className=""
                                src={item.image}
                                alt=""
                              />
                              {item.is_equipped &&
                              parseInt(item.is_equipped) === 1 ? (
                                <Button className="btn-avt-selected">
                                  Selected
                                </Button>
                              ) : parseInt(item.is_equipped) === 0 ? (
                                <Button
                                  className="btn-avt-select"
                                  onClick={() => item_equipped(item)}
                                >
                                  Select
                                </Button>
                              ) : (
                                <Button
                                  className="btn-avt-purchase"
                                  onClick={() => item_purchased(item)}
                                >
                                  <img
                                    width={15}
                                    className=""
                                    src={awicon}
                                    alt=""
                                  />{" "}
                                  {item.coins}
                                </Button>
                              )}
                            </li>
                          ))
                        ) : (
                          <p className="text-center">No data yet</p>
                        )}
                      </ul>
                    </div>
                  </TabPane>
                  <TabPane tabId="5">
                    <div className="profile-utlities-wrapper hand">
                      <ul className="mb-0 list-unstyled profile-utlities">
                        {AvatarTabData && AvatarTabData.length > 0 ? (
                          AvatarTabData.map((item, index) => (
                            <li key={index}>
                              <img
                                width={68}
                                className=""
                                src={item.image}
                                alt=""
                              />
                              {item.is_equipped &&
                              parseInt(item.is_equipped) === 1 ? (
                                <Button className="btn-avt-selected">
                                  Selected
                                </Button>
                              ) : parseInt(item.is_equipped) === 0 ? (
                                <Button
                                  className="btn-avt-select"
                                  onClick={() => item_equipped(item)}
                                >
                                  Select
                                </Button>
                              ) : (
                                <Button
                                  className="btn-avt-purchase"
                                  onClick={() => item_purchased(item)}
                                >
                                  <img
                                    width={15}
                                    className=""
                                    src={awicon}
                                    alt=""
                                  />{" "}
                                  {item.coins}
                                </Button>
                              )}
                            </li>
                          ))
                        ) : (
                          <p className="text-center">No data yet</p>
                        )}
                      </ul>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(UserProfile)
