import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import { connect } from "react-redux"
import { Container } from "reactstrap"

import {  useLocation,Link } from "react-router-dom"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"


//pass request back end
import axios from "axios";
//i18n
import { withTranslation } from "react-i18next"
import { SECURITY_KEY, BASE_URL } from "../../global";


// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"



const Header = props => {
  const [search, setsearch] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 992) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  let location = useLocation();

  //Maitance details
  const [MaintenanceView, setMaintenanceView] = useState(true);
const toggleMaintance = () => {
  setMaintenanceView(!MaintenanceView);
}
  useEffect(() => {
    if (localStorage.getItem("authStudentUser")) {
      fetchMaintenance();
    }
  }, [props.success]);

const [IsMaintenance, setMaintenance] = useState([]);

const fetchMaintenance = async () => {
  try {
    const response = await axios.post(
      BASE_URL+"studentlogin/studentMaintenance"
    )
    if (response.data.length > 0) {
      setMaintenance(response.data[0])
    } else {
      console.error("Error fetching data")
    }
  } catch (error) {
    console.error("Error fetching data:", error)
  }
}
//Maintance details

 // console.log(location.pathname);

  if(location.pathname === '/' || location.pathname === '/dashboard'){

  return (
    <React.Fragment>
      <header id="page-topbar" className="bg-dark-blue">
        <div className="navbar-header">
          <Container className="custom-wt">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="text-white mb-0" onClick={()=> {window.location.reload();}}>Student App</h4>
              </div>
              <div className="d-flex align-items-center">
                <NotificationDropdown />
                <ProfileMenu />
              </div>
            </div>
          </Container>
        </div>
        {IsMaintenance && MaintenanceView ? (
        IsMaintenance.sm_narration &&
        IsMaintenance.sm_narration != "" &&
        parseInt(IsMaintenance.is_visible) == 0 ? (
        <div className="alert-warning">
          <Container className="custom-wt">
            <div className="d-flex justify-content-between align-items-center py-3">
              <p className="mb-0 font-size-16 fw-medium text-dark">
              {IsMaintenance
                  ? IsMaintenance.sm_narration &&
                    IsMaintenance.sm_narration != null &&
                    IsMaintenance.sm_narration != ""
                    ? IsMaintenance.sm_narration
                    : null
                  : null}
              </p>
              <i style={{cursor:"pointer"}} className="mdi mdi-close-thick" onClick={toggleMaintance}></i>
            </div>
          </Container>
        </div>
        ) : null
        ) : null}
      </header>
    </React.Fragment>
  )
  }else{
    return (null)
  }
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
