import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/pf1.png"
//userprofile order start
import usprofile1 from "../../../assets/images/services-icon/us3.png"
import usprofile2 from "../../../assets/images/services-icon/us4.png"
import usprofile3 from "../../../assets/images/services-icon/us5.png"
import usprofile4 from "../../../assets/images/services-icon/us6.png"
import usprofile5 from "../../../assets/images/services-icon/us7.png"
import usprofile6 from "../../../assets/images/services-icon/us8.png"

//Boys user Profile
import b1 from "../../../assets/images/users/boyuser/b1.png";
import b2 from "../../../assets/images/users/boyuser/b2.png";
import b3 from "../../../assets/images/users/boyuser/b3.png";
import b4 from "../../../assets/images/users/boyuser/b4.png";
import b5 from "../../../assets/images/users/boyuser/b5.png";
import b6 from "../../../assets/images/users/boyuser/b6.png";
import b7 from "../../../assets/images/users/boyuser/b7.png";
import b8 from "../../../assets/images/users/boyuser/b8.png";
import b9 from "../../../assets/images/users/boyuser/b9.png";
import b10 from "../../../assets/images/users/boyuser/b10.png";

//girl user profile
import g1 from "../../../assets/images/users/girluser/g1.png";
import g2 from "../../../assets/images/users/girluser/g2.png";
import g3 from "../../../assets/images/users/girluser/g3.png";
import g4 from "../../../assets/images/users/girluser/g4.png";
import g5 from "../../../assets/images/users/girluser/g5.png";
import g6 from "../../../assets/images/users/girluser/g6.png";
import g7 from "../../../assets/images/users/girluser/g7.png";
import g8 from "../../../assets/images/users/girluser/g8.png";
import g9 from "../../../assets/images/users/girluser/g9.png";
import g10 from "../../../assets/images/users/girluser/g10.png";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")
  const [userProfileImg, setUserProfileimg] = useState(localStorage.getItem("userprofile") || null);
  const [userGender, setUserGender] = useState(
    localStorage.getItem("usergender") || null
  )
  useEffect(() => {
    if (localStorage.getItem("authStudentUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authStudentUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authStudentUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect custom-hd-dd pe-0"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user obj-fit-cover"
            src={
              userProfileImg && userGender
              ? 
              userGender === 'Male' ? 
                parseInt(userProfileImg) === 1
                ? b1
                : parseInt(userProfileImg) === 2
                ? b2
                : parseInt(userProfileImg) === 3
                ? b3
                : parseInt(userProfileImg) === 4
                ? b4
                : parseInt(userProfileImg) === 5
                ? b5
                : parseInt(userProfileImg) === 6
                ? b6
                : parseInt(userProfileImg) === 7
                ? b7
                : parseInt(userProfileImg) === 8
                ? b8
                : parseInt(userProfileImg) === 9
                ? b9
                : parseInt(userProfileImg) === 10
                ? b10 : b1
              : userGender === 'Female' ? 
                  parseInt(userProfileImg) === 1
                  ? g1
                  : parseInt(userProfileImg) === 2
                  ? g2
                  : parseInt(userProfileImg) === 3
                  ? g3
                  : parseInt(userProfileImg) === 4
                  ? g4
                  : parseInt(userProfileImg) === 5
                  ? g5
                  : parseInt(userProfileImg) === 6
                  ? g6
                  : parseInt(userProfileImg) === 7
                  ? g7
                  : parseInt(userProfileImg) === 8
                  ? g8
                  : parseInt(userProfileImg) === 9
                  ? g9
                  : parseInt(userProfileImg) === 10
                  ? g10 : g1
                : 
                parseInt(userProfileImg) === 1
                ? b1
                : parseInt(userProfileImg) === 2
                ? b2
                : parseInt(userProfileImg) === 3
                ? b3
                : parseInt(userProfileImg) === 4
                ? b4
                : parseInt(userProfileImg) === 5
                ? b5
                : parseInt(userProfileImg) === 6
                ? b6
                : parseInt(userProfileImg) === 7
                ? b7
                : parseInt(userProfileImg) === 8
                ? b8
                : parseInt(userProfileImg) === 9
                ? b9
                : parseInt(userProfileImg) === 10
                ? b10 : b1
              : b1
            }
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile" className="font-size-16">
            {" "}
            {props.t("Profile")}{" "}
          </DropdownItem>

          <Link to="/logout" className="dropdown-item font-size-16">
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
