import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VerticalLayout from "components/VerticalLayout";
import HorizontalLayout from "components/HorizontalLayout";
import {useEffect} from "react";
import axios from "axios";
//import { BASE_URL} from "../../global";
//constants
import { layoutTypes } from "../../constants/layout";
import { SECURITY_KEY, BASE_URL } from "../../global";
const Authmiddleware = (props) => {

  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }));

  const getLayout = (layoutType) => {
    let Layout = VerticalLayout;
    switch (layoutType) {
      case layoutTypes.VERTICAL:
        Layout = VerticalLayout;
        break;
      case layoutTypes.HORIZONTAL:
        Layout = HorizontalLayout;
        break;
      default:
        break;
    }
    return Layout;
  };

  const Layout = getLayout(layoutType);

  
  const authUser = JSON.parse(localStorage.getItem('authStudentUser')) || null;
//   console.log(tutor_key);
// console.log(tutor_key.pk_tut_key);
 useEffect(() => {
    if (authUser && authUser != "" && authUser != null) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}` 
      axios.get(BASE_URL+'studentlogin/checkUser').then(responseStudent => {
            // Assuming the API response is an array of objects with 'id' and 'value' properties
           
              if (responseStudent.data.length > 0) {
                    if (responseStudent.data[0]['pk_student_key'] !== '') {
                      
                      localStorage.setItem("userprofile", responseStudent.data[0]["st_avatar"])
                      localStorage.setItem("usergender", responseStudent.data[0]["st_gender"])
                  } else {
                    localStorage.clear();
                    <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
                  }

              }else{
                localStorage.clear();
                <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
              }
            
        })
        .catch(error => {
          console.log(error.response.data.error)
          if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
           localStorage.clear();
           <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
          }

          // if(error){
          //   localStorage.clear();
          // <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
          // }
          
        });


    }
    else{
      localStorage.clear();
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    }
   

}, [props.children]);


  if (!localStorage.getItem("authStudentUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return (
    <React.Fragment>
      <Layout>{props.children}</Layout>
    </React.Fragment>);
};

export default Authmiddleware;
