import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';

class Doughnut extends Component {
    getOption = () => {
        return {

            toolbox: {
                show: true,
            },
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b}: {c} ({d}%)"
            },
            legend: {
                orient: 'vertical',
                x: 'right',
                data: ['Correct Ans', 'Wrong Ans'],
                textStyle: {
                    color: ['#74788d']
                }
            },
            color: ['#009688', '#D83D59'],
            series: [
                {
                    name: 'Accuracy',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            show: false,
                            textStyle: {
                                fontSize: '30',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            show: true
                        }
                    },
                    data: [
                        { value: 335, name: 'Correct Ans' },
                        { value: 310, name: 'Wrong Ans' },
                        
                    ]
                }
            ]
        };
    };
    render() {
        return (
            <React.Fragment>
                <ReactEcharts style={{ height: "220px" }} option={this.getOption()} />
            </React.Fragment>
        );
    }
}



export default Doughnut;
