import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import {
  Container,
  Form,
  FormFeedback,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"

// Redux
import { connect, useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import UAParser from 'ua-parser-js';
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoDark from "../../assets/images/logo-dark.png"
import doc from "../../assets/images/services-icon/doc.svg"
import moment from "moment"

//pass request back end
import axios from "axios"
//import { BASE_URL } from "../../global"
//alert
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { useLocation } from "react-router-dom"
import { SECURITY_KEY, BASE_URL } from "../../global";
// import us2 from "../../assets/images/services-icon/us2.png"
import usprofile1 from "../../assets/images/services-icon/us3.png"
import usprofile2 from "../../assets/images/services-icon/us4.png"
import usprofile3 from "../../assets/images/services-icon/us5.png"
import usprofile4 from "../../assets/images/services-icon/us6.png"
import usprofile5 from "../../assets/images/services-icon/us7.png"
import usprofile6 from "../../assets/images/services-icon/us8.png"


//Boys user Profile
import b1 from "../../assets/images/users/boyuser/b1.png";
import b2 from "../../assets/images/users/boyuser/b2.png";
import b3 from "../../assets/images/users/boyuser/b3.png";
import b4 from "../../assets/images/users/boyuser/b4.png";
import b5 from "../../assets/images/users/boyuser/b5.png";
import b6 from "../../assets/images/users/boyuser/b6.png";
import b7 from "../../assets/images/users/boyuser/b7.png";
import b8 from "../../assets/images/users/boyuser/b8.png";
import b9 from "../../assets/images/users/boyuser/b9.png";
import b10 from "../../assets/images/users/boyuser/b10.png";

//girl user profile
import g1 from "../../assets/images/users/girluser/g1.png";
import g2 from "../../assets/images/users/girluser/g2.png";
import g3 from "../../assets/images/users/girluser/g3.png";
import g4 from "../../assets/images/users/girluser/g4.png";
import g5 from "../../assets/images/users/girluser/g5.png";
import g6 from "../../assets/images/users/girluser/g6.png";
import g7 from "../../assets/images/users/girluser/g7.png";
import g8 from "../../assets/images/users/girluser/g8.png";
import g9 from "../../assets/images/users/girluser/g9.png";
import g10 from "../../assets/images/users/girluser/g10.png";
//import boyImages from "../../assets/images/users/boyuser/*.png"; // Import all images as a group
const Login = props => {
  document.title = "MyGooRoo Student App"

  // modal
  const [modal_center, setmodal_center] = useState(false)

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  // const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isIpData, setIpData] = useState('');
  const [isContentDiv, setIsContentDiv] = useState(1)
  const [isAvathor, setIsAvathor] = useState(0)

  const [IsContent, setContent] = useState([])
  const [IsContentFilter, setContentFilter] = useState([])
  const [userData, setuserData] = useState([])
  const [userLogin, setUserLogin] = useState([])
  const [userAvatherSeleced, setAvatherSeleced] = useState(null)
  const selectprofile = data => {
    setAvatherSeleced(data)
  }

  const [userToken, setUserToken] = useState([])

  const location = useLocation()
  useEffect(() => {
    if (!localStorage.getItem("authStudentUser")) {
      fetchContentData();
      fetchIpData();
    } else {
      props.router.navigate("/")
    }
  }, [location])

  const forgot_password_alert = async () => {
    toast.info(
      "To reset your password, please contact your tutor or tuition centre.",
      {
        autoClose: 3000, // 3 seconds in this example
      }
    )
  }

  const [IsTearmsCondition, setTearmsCondition] = useState([])
  const submitCondition = async () => {
    if (userToken && userToken != "" && userToken != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`
      axios
        .post(BASE_URL+"studentlogin/teamsCondition", {
          flag: 1,
        })
        .then(responsetearms => {
          if (responsetearms.data == "1") {
            if (isAvathor && isAvathor !== null && parseInt(isAvathor) !== 0) {
              setIsContentDiv(3)
            } else {
              
              localStorage.setItem("userprofile", userData.st_avatar)
              localStorage.setItem("usergender", userData.st_gender)
              localStorage.setItem("cent_timezone_identifier", userData.cent_timezone_identifier)
              localStorage.setItem("cent_timezone", userData.cent_timezone)
              localStorage.setItem("authStudentUser", JSON.stringify(userToken))
              props.router.navigate("/")
            }
          } else {
            toast.error("Not update Terms & Conditions accepted", {
              autoClose: 3000, // 3 seconds in this example
            })
          }
        })
        .catch(error => {
          toast.error("Not update Terms & Conditions accepted", {
            autoClose: 3000, // 3 seconds in this example
          })
          console.log(error)
        })
    } else {
      toast.error("Not update Terms & Conditions accepted", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

    // if(isAvathor && isAvathor !==null && parseInt(isAvathor)!== 0){
    // setIsContentDiv(3);
    // }else{
    //   localStorage.setItem(
    //     "authStudentUser",
    //     JSON.stringify(userToken)
    //   )
    //   props.router.navigate("/");
    // }
  }

  const submitConditionDenide = async () => {
    localStorage.clear()
    fetchContentData()
    setIsContentDiv(1)
  }
  const submitAvathor = async () => {
    if (userToken && userToken != "" && userToken != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`
      axios
        .post(BASE_URL+"studentlogin/updateAvathor", {
          st_avatar: userAvatherSeleced,
        })
        .then(responseAvather => {
          if (responseAvather.data == "1") {
            localStorage.setItem("userprofile", userAvatherSeleced)
            localStorage.setItem("usergender", userData.st_gender)
            localStorage.setItem("cent_timezone_identifier", userData.cent_timezone_identifier)
            localStorage.setItem("cent_timezone", userData.cent_timezone)
            localStorage.setItem("authStudentUser", JSON.stringify(userToken))
            props.router.navigate("/")
          } else {
            toast.error("Not set profile image", {
              autoClose: 3000, // 3 seconds in this example
            })
          }
        })
        .catch(error => {
          toast.error("Not set profile image", {
            autoClose: 3000, // 3 seconds in this example
          })
          console.log(error)
        })
    } else {
      toast.error("Not set profile image", {
        autoClose: 3000, // 3 seconds in this example
      })
    }

    // localStorage.setItem(
    //   "authStudentUser",
    //   JSON.stringify(userToken)
    // )
    // props.router.navigate("/")
  }

  const fetchContentData = async () => {
    try {
      const response = await axios.post(
        BASE_URL+"studentlogin/studentContent"
      )
      if (response.data.length > 0) {
        setContent(response.data)
      } else {
        console.error("Error fetching data")
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }


  const fetchIpData = async () => {

     
    try {
      // Get the user's IP address
     //const ipResponse = await axios.get('https://api.ipify.org?format=json');
     const ipResponse =  await axios.get('https://jsonip.com');
     // const ipAddress = ipResponse.data.ip;
    // console.log(ipResponse);
      if (ipResponse && ipResponse.data && ipResponse.data.ip && ipResponse.data.ip!='') {
        const ipAddress = ipResponse.data.ip;
        //console.log(ipAddress);
        setIpData(ipAddress)
      } else {
        //setTimeout(fetchIpData, 3000);
        console.error("Error fetching ip data")
      }
    } catch (error) {
      //setTimeout(fetchIpData, 3000);
      console.error("Error fetching ip data:", error)
    }
  }

  // const { user } = useSelector(state => ({
  // user: state.Account.user,
  // }))

  // useEffect(() => {
  // if (user && user) {
  // setUserLogin({
  // email: user.email,
  // password: user.password,
  // })
  // }
  // }, [user])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      username: userLogin.username || "",
      password: userLogin.password || "",
      keepLogin: false,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required("Username is required")
        .min(2, "Minimum 2 characters is required")
        .max(30, "Maximum 30 characters is required")
        .matches(/^[^'"`\s’]+$/, "Username is required"),
      password: Yup.string()
        .required("Password is required")
        .min(5, "Minimum 5 characters is required")
        .max(30, "Maximum 30 characters is required")
        .matches(
          /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
          "Avoid start & end with a space or comma"
        ),
    }),
    onSubmit: async values => {
      // console.log(values);
        // Get the user's system information
        const parser = new UAParser();
        const systemInfo = parser.getResult();
      //disable login button
      setIsSubmitting(true)

      try {
        const response = await axios.post(
          BASE_URL+"studentlogin/loginStudent",
          {
            ...values,
            ip_address : isIpData,
            system_info: systemInfo
          }
        )
        if (response.data.length > 0) {
          if (response.data[0]["pk_student_key"] !== "") {
            // toast.success("Success! Student slot assigned", {
            //   autoClose: 3000, // 3 seconds in this example
            // });
            // localStorage.setItem("jwt-token", JSON.stringify(response.data[0]['token']));
            setuserData(response.data[0]);
            if (
              response.data[0]["st_avatar"] == null ||
              response.data[0]["st_avatar"] == "" ||
              response.data[0]["st_avatar"] == "0"
            ) {
              setIsAvathor(1)
            }
            
            setUserToken(response.data[0]["token"])

            const filteredData = IsContent.filter(
              item => item.pk_content_key === "8C516253795442BA"
            )
            if (
              parseInt(response.data[0]["logged_in_flag"]) === 0 &&
              filteredData.length > 0
            ) {
              setTearmsCondition(filteredData[0])
              setIsContentDiv(2)
            } else if (
              response.data[0]["st_avatar"] == null ||
              response.data[0]["st_avatar"] == "" ||
              response.data[0]["st_avatar"] == "0"
            ) {
              setIsContentDiv(3)
            } else {
              localStorage.setItem("userprofile", response.data[0]["st_avatar"])
              localStorage.setItem("usergender", response.data[0]["st_gender"])
              localStorage.setItem("cent_timezone_identifier", response.data[0]["cent_timezone_identifier"])
              localStorage.setItem("cent_timezone", response.data[0]["cent_timezone"])
              localStorage.setItem(
                "authStudentUser",
                JSON.stringify(response.data[0]["token"])
              )
              localStorage.setItem(
                "subject",
                JSON.stringify(response.data[0]["subject_id"])
              )
              props.router.navigate("/")
            }

            setIsSubmitting(false)
          } else {
            toast.error("The entered username or password is wrong.", {
              autoClose: 3000, // 3 seconds in this example
            })
            setIsSubmitting(false)
          }
        } else {
          toast.error("The entered username or password is wrong.", {
            autoClose: 3000, // 3 seconds in this example
          })
          setIsSubmitting(false)
        }
      } catch (error) {
        toast.error("The entered username or password is wrong.", {
          autoClose: 3000, // 3 seconds in this example
        })
        console.error("Error fetching data:", error)

        setIsSubmitting(false) // Re-enable the submit button
      } finally {
        setIsSubmitting(false) // Re-enable the submit button
      }

      //dispatch(loginUser(values, props.router.navigate));
    },
  })


  const [passwordVisible, setPasswordVisible] = useState(false);


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const boyImages = [{image:b1, text:'Astronaut'}, {image:b2, text:'Chemist'}, {image:b3, text:'Doctor'}, {image:b4, text:'Engineer'}, {image:b5, text:'Entrepreneur'},
    {image:b6, text:'Influencer'}, {image:b7, text:'Politician'}, {image:b8, text:'Programmer'}, {image:b9, text:'Scientist'}, {image:b10, text:'Teacher'}];
    const girlImages = [{image:g1, text:'Astronaut'}, {image:g2, text:'Chemist'}, {image:g3, text:'Doctor'}, {image:g4, text:'Engineer'}, {image:g5, text:'Entrepreneur'},
    {image:g6, text:'Influencer'}, {image:g7, text:'Politician'}, {image:g8, text:'Programmer'}, {image:g9, text:'Scientist'}, {image:g10, text:'Teacher'}];
    //console.log(userData)
  return (
    <React.Fragment>
      <ToastContainer autoClose={3000} />
      <div
        className="account-pages mt-5 pt-2"
        style={{ display: isContentDiv === 1 ? "block" : "none" }}
      >
        <Container>
          <div className="lg-wrapper">
            <div className="text-center">
              <img width={250} src={logoDark} alt="logo" />
            </div>
            <div className="">
              <Form
                className="mt-4"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
                action="#"
              >
                <div className="mb-3 d-flex align-items-center gap-3">
                  <i className="mdi mdi-account text-dark font-size-24"></i>
                  <div className="w-100">
                    <Input
                      name="username"
                      className="form-control lg-input"
                      placeholder="Enter Username"
                      type="username"
                      id="username"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.username || ""}
                      invalid={
                        validation.touched.username &&
                        validation.errors.username
                          ? true
                          : false
                      }
                    />
                    {validation.touched.username &&
                    validation.errors.username ? (
                      <FormFeedback type="invalid">
                        {validation.errors.username}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3 d-flex align-items-center gap-3">
                  <i className="mdi mdi-lock text-dark font-size-24"></i>
                  <div className="w-100 position-relative">
                    <Input
                      name="password"
                      value={validation.values.password || ""}
                      type={passwordVisible ? "text" : "password"}
                      className="form-control lg-input"
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />

                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}

                    <div className="password-hider position-absolute" onClick={togglePasswordVisibility}>
                      {passwordVisible ? <i className="mdi mdi-eye font-size-24"></i> : <i className="mdi mdi-eye-off font-size-24"></i>}
                    
                    {/* <i className="mdi mdi-eye-off font-size-24"></i> */}
                    </div>
                  </div>
                </div>

                <div className="mb-3 border-bottom-dark mb-3 pb-4">
                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="keepLogin"
                      checked={validation.values.keepLogin}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                    />
                    <label
                      className="form-check-label font-size-14"
                      htmlFor="keepLogin"
                    >
                      Keep me logged in{" "}
                    </label>
                  </div>
                  <div className="text-center mb-2">
                    <button
                      className="btn btn-danger cs-btn-danger"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Log In
                    </button>
                  </div>
                </div>

                <div className="mt-2 mb-0 text-center font-size-16">
                  <Link onClick={forgot_password_alert}>
                    I forgot my password
                  </Link>
                </div>
              </Form>

              <div className="lg-utils d-flex justify-content-center mt-3">
                {IsContent.length > 0 ? (
                  <>
                    {IsContent.map((item, index) =>
                      item.pk_content_key === "8C516253795442BA" ||
                      item.pk_content_key === "C4818A4A58384DC5" ? (
                        <>
                          {index > 0 && (
                            <span className="vertical-divider"></span>
                          )}
                          <p
                            className="mb-0 font-size-15 text-dark cursor-pointer me-3 ms-3"
                            key={index}
                            onClick={async e => {
                              e.preventDefault()
                              setContentFilter(item)
                              tog_center() // Fix: Call the function
                            }}
                          >
                            {item.content_title}
                          </p>
                          {/* {index < IsContent.length - 1 && (
                        <span className="text-primary mx-1">|</span>
                      )} */}
                        </>
                      ) : null
                    )}
                  </>
                ) : null}
                {/* <p className="mb-0 font-size-15 text-dark cursor-pointer" onClick={tog_center}>
              Terms & Conditions
            </p>
            <span className="vertical-divider"></span>
            <p className="mb-0 font-size-15 text-dark">Privacy Policy</p> */}
              </div>
            </div>

            <Modal isOpen={modal_center} toggle={tog_center} centered>
              <ModalHeader className="mt-0" toggle={tog_center}>
                <div className="d-flex align-items-center gap-4">
                  <img width={36} src={doc} alt="logo" />
                  <div>
                    <h4 className="mb-0 font-size-18">
                      {IsContentFilter && IsContentFilter.content_title != ""
                        ? IsContentFilter.content_title
                        : ""}
                    </h4>
                    <p className="mb-0 font-size-15">
                      Last Modified{" "}
                      {IsContentFilter && IsContentFilter.updated_on != ""
                        ? moment(
                            IsContentFilter.updated_on,
                            "YYYY-MM-DDTHH:mm:ssZ"
                          ).format("MMM YYYY")
                        : ""}
                    </p>
                  </div>
                </div>
              </ModalHeader>
              <ModalBody>
                {IsContentFilter.content_details !== "" ? (
                  <div>
                    <h6>
                      <strong>{IsContentFilter.content_subject}</strong>
                    </h6>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: IsContentFilter.content_details,
                      }}
                    />
                  </div>
                ) : (
                  <div>No content available</div>
                )}
              </ModalBody>
            </Modal>
          </div>
        </Container>
      </div>

      <div
        className="account-pages mt-5 pt-2"
        style={{ display: isContentDiv === 2 ? "block" : "none" }}
      >
        <Container>
          <div className="lg-wrapper">
            <div className="text-center mb-2">
              <Modal isOpen={isContentDiv === 2 ? true : false} centered>
                <ModalHeader className="mt-0">
                  <div className="d-flex align-items-center gap-4">
                    <img width={36} src={doc} alt="logo" />
                    <div>
                      <h4 className="mb-0 font-size-18">
                        {IsTearmsCondition &&
                        IsTearmsCondition.content_title != ""
                          ? IsTearmsCondition.content_title
                          : ""}
                      </h4>
                      <p className="mb-0 font-size-15">
                        Last Modified{" "}
                        {IsTearmsCondition && IsTearmsCondition.updated_on != ""
                          ? moment(
                              IsTearmsCondition.updated_on,
                              "YYYY-MM-DDTHH:mm:ssZ"
                            ).format("MMM YYYY")
                          : ""}
                      </p>
                    </div>
                  </div>
                </ModalHeader>
                <ModalBody>
                  {IsTearmsCondition.content_details !== "" ? (
                    <div>
                      <h6>
                        <strong>{IsTearmsCondition.content_subject}</strong>
                      </h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: IsTearmsCondition.content_details,
                        }}
                      />
                    </div>
                  ) : (
                    <div>No content available</div>
                  )}
                  <div className="text-end mt-2">
                    <button
                      className="btn btn-danger me-2"
                      type="button"
                      onClick={() => submitConditionDenide()}
                    >
                      DECLINE
                    </button>
                    <button
                      className="btn btn-success "
                      type="button"
                      onClick={() => submitCondition()}
                    >
                      ACCEPT
                    </button>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </Container>
      </div>

      <div
        className="account-pages mt-5 pt-2"
        style={{ display: isContentDiv === 3 ? "block" : "none" }}
      >
        <Container>
          <div className="">
            <div className="">
              <div className="text-center mb-3">
                {" "}
                <h2 className="mt-4 fw-bold">Select your Avatar</h2>
                <h4 className="mt">Choose one!</h4>
              </div>
              <div className="d-flex justify-content-center">
                <div className="media-list">
                  <div className="image-display-wrapper1 mt-4">
                    <ul className="list-unstyled mb-0">

                    {userData && userData.st_gender && userData.st_gender === 'Male' ? 
                    
                    boyImages.map((boyData, index) => (
                      <li
                        key={index}
                        className={
                          userAvatherSeleced && userAvatherSeleced === index + 1
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(index + 1)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={boyData.image} // Assuming your image URLs follow this pattern
                          alt=""
                        />
                        <p class="text-center font-size-16 mb-1"><strong>{boyData.text}</strong></p>
                      </li>
                    ))
                  : userData && userData.st_gender && userData.st_gender === 'Female' ?
                    girlImages.map((girlData, index) => (
                      <li
                        key={index}
                        className={
                          userAvatherSeleced && userAvatherSeleced === index + 1
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(index + 1)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={girlData.image} // Assuming your image URLs follow this pattern
                          alt=""
                        />
                        <p class="text-center font-size-16 mb-1"><strong>{girlData.text}</strong></p>
                      </li>
                    )) 
                    : boyImages.map((boyData, index) => (
                      <li
                        key={index}
                        className={
                          userAvatherSeleced && userAvatherSeleced === index + 1
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(index + 1)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={boyData.image} // Assuming your image URLs follow this pattern
                          alt=""
                        />
                        <p class="text-center font-size-16 mb-1"><strong>{boyData.text}</strong></p>
                      </li>
                    ))
                    
                    }

                      {/* <li
                        className={
                          userAvatherSeleced && userAvatherSeleced === 1
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(1)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={usprofile1}
                          alt=""
                        />
                      </li>
                      <li
                        className={
                          userAvatherSeleced && userAvatherSeleced === 2
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(2)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={usprofile2}
                          alt=""
                        />
                      </li>
                      <li
                        className={
                          userAvatherSeleced && userAvatherSeleced === 3
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(3)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={usprofile3}
                          alt=""
                        />
                      </li>
                      <li
                        className={
                          userAvatherSeleced && userAvatherSeleced === 4
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(4)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={usprofile4}
                          alt=""
                        />
                      </li>
                      <li
                        className={
                          userAvatherSeleced && userAvatherSeleced === 5
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(5)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={usprofile5}
                          alt=""
                        />
                      </li>
                      <li
                        className={
                          userAvatherSeleced && userAvatherSeleced === 6
                            ? "selected"
                            : ""
                        }
                        onClick={() => selectprofile(6)}
                      >
                        <img
                          width={94}
                          height={94}
                          className="rounded-circle obj-fit-cover"
                          src={usprofile6}
                          alt=""
                        />
                      </li> */}
                    </ul>
                  </div>

                  <div className=" mt-4 text-center">
                    <button
                      className="btn btn-danger cs-btn-danger text-uppercase btn btn-secondary mt-3"
                      onClick={() => submitAvathor()}
                      disabled={
                        userAvatherSeleced && userAvatherSeleced !== null
                          ? false
                          : true
                      }
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <h1>avathor Page</h1>
       <button className="btn btn-danger cs-btn-danger" type="button" onClick={() => submitAvathor()}>
          go to Dashboard
        </button> */}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
